import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { httpsCallable } from "firebase/functions"
import { Link } from "react-router-dom"
import { functions } from "~/services/firebase"

interface CommunityTokenResponse {
  token: string
}

interface JoyCommunityModalProps {
  open: boolean
  onClose: () => void
}

function JoyCommunityModal({ open, onClose }: JoyCommunityModalProps) {
  const [communityToken, setCommunityToken] = useState<string | null>(null)
  const [fetchingToken, setFetchingToken] = useState(false)

  const { currentUser } = useAuth()

  const fetchCommunityTokenForUser = async (): Promise<string> => {
    // Get the function reference
    const getCommunityTokenCloudFunction = httpsCallable<
      {},
      CommunityTokenResponse
    >(functions, "getCommunityTokenForUser")

    try {
      // Fetch the community token
      const result = await getCommunityTokenCloudFunction()
      if (!result.data?.token) {
        throw new Error("Token not found in the response")
      }
      return result.data.token
    } catch (error) {
      // UI will show an error message if the token is empty
      console.error("Error fetching community token:", error)
      throw error // Re-throw the error if you want to handle it further up the call stack
    }
  }

  const fetchCommunityToken = async () => {
    setFetchingToken(true)
    try {
      if (currentUser?.email) {
        const token = await fetchCommunityTokenForUser()
        setCommunityToken(token)
      }
    } catch (error) {
      // User feedback on error will be given by community token being empty..
      console.error("getCommunityTokenForUser error: " + JSON.stringify(error))
    } finally {
      setFetchingToken(false)
    }
  }

  useEffect(() => {
    if (open) {
      fetchCommunityToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose()
        }
      }}
    >
      <DialogContent className="flex flex-col items-center justify-center max-w-[500px] gap-4 p-10">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            You&apos;re about to enter the Joy Community forum.
          </DialogTitle>
        </DialogHeader>

        <p className="text-xl font-bold px-2.5 text-left">
          This forum is exclusive to Joy users — a secure space for discussions.
        </p>

        <p className="text-xl font-bold px-2.5 text-left">
          Remember not to share any client details here as you are now leaving
          your personal Joy Notes space. Welcome!
        </p>

        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="ghost"
            onClick={onClose}
          >
            Back to Joy Notes
          </Button>
          {communityToken ? (
            <Link
              to={`https://community.joy.day/?authtoken=${communityToken}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClose}
              className="no-underline"
            >
              <Button>Continue</Button>
            </Link>
          ) : (
            <Button disabled>Continue</Button>
          )}
        </div>
        <div
          className={`text-center ${!communityToken && !fetchingToken ? "block" : "hidden"}`}
        >
          Error: Could not get community token.
          <p className="block">
            Please try again later or contact support@joy.day.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default JoyCommunityModal
