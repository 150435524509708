import { useCallback, useContext, useMemo, useState } from "react"
import { doc, updateDoc } from "firebase/firestore"
import { useAuth } from "~/context/AuthContext"
import { UserContext } from "~/context/UserContext"
import { db } from "~/services/firebase"
import { UserStatisticsType } from "~/types/userTypes"

const isEqualPartial = (
  partial: Partial<UserStatisticsType>,
  full: UserStatisticsType | undefined
): boolean => {
  if (!full) return false

  return Object.keys(partial).every((key) => {
    const k = key as keyof UserStatisticsType
    return JSON.stringify(partial[k]) === JSON.stringify(full[k])
  })
}

export function useUserStatistics(): [
  UserStatisticsType | null,
  (updates: Partial<UserStatisticsType>) => void,
  boolean,
] {
  const userContext = useContext(UserContext)
  const user = userContext?.user
  const { currentUser } = useAuth()

  const [isPending, setIsPending] = useState<boolean>(false)

  const updateUserStatistics = useCallback(
    async (updates: Partial<UserStatisticsType>) => {
      if (!isEqualPartial(updates, user?.statistics) && currentUser?.uid) {
        try {
          setIsPending(true)
          const userRef = doc(db, "users", currentUser?.uid)
          await updateDoc(userRef, {
            statistics: { ...user?.statistics, ...updates },
          })
        } catch (error) {
          console.error(error)
        } finally {
          setIsPending(false)
        }
      }
    },
    [currentUser?.uid, user?.statistics]
  )

  return useMemo(
    () => [user?.statistics || null, updateUserStatistics, isPending],
    [user?.statistics, updateUserStatistics, isPending]
  )
}
