import * as React from "react"
import { useDeepCompareEffect } from "ahooks"
import { useNavigate, useParams } from "react-router-dom"
import ClientDetailsModal from "~/components/ClientDetailsModal"
import EditClientModal from "~/components/EditClientModal"
import { toast } from "~/components/ui"
import { ConfirmationModal } from "~/components/ui/confirmation-modal"
import { useAuth } from "~/context/AuthContext"
import { useDeleteClient } from "~/hooks/firestore/useClients"
import {
  useGetNoteById,
  useMarkNoteAsViewed,
  useSubscriptionNotesByClient,
  useUpdateNotesViewedWithUndo,
} from "~/hooks/useNotes"
import { clientFields } from "~/utils/noteUtils"
import { PROMPTS } from "~/utils/prompts"
import ClientModal from "./ClientModal"
import NoteHeader from "./NoteHeader"
import { NoteItem } from "./NoteItem"
import { Note, type Field } from "./types"

export default function NoteDetails() {
  const { noteId } = useParams()
  const navigate = useNavigate()

  const { currentUser } = useAuth()
  const markNoteAsViewed = useMarkNoteAsViewed()

  const noteInfo = useGetNoteById({ noteId: noteId! })
  const noteData = noteInfo.data
  const noteHasClient = Boolean(noteData?.clientId ?? false)

  // Redirect to notes page if note does not exist
  useDeepCompareEffect(() => {
    if (noteInfo.error) {
      navigate("/notes")
    }
  }, [noteInfo.error])

  // Side effect to mark note as viewed
  useDeepCompareEffect(() => {
    if (noteData && !noteData.viewed) {
      markNoteAsViewed.push({ noteId: noteData.id }).catch(console.error)
    }
  }, [noteData?.id])

  const clientNotes = useSubscriptionNotesByClient({
    clientId: noteData?.clientId ?? "",
    onSnapshot: (snapshot) => {
      const _notes = snapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id }) as Note
      )

      for (const note of _notes) {
        if (!note.viewed) {
          // TODO : Verify handling of this promise
          markNoteAsViewed.push({ noteId: note.id }).catch(console.error)
        }
      }
    },
  })

  const notes = React.useMemo(() => {
    if (!noteInfo.data) return []

    // If note has no client, return only the note
    if (!noteHasClient) {
      return [noteInfo.data]
    }

    const _noteId = noteInfo.data?.id

    return [...(clientNotes.data ?? [])]
      .filter((note) => !note.deletedAt || !note.viewedWithUndo)
      .sort((a, b) => {
        if (a.id === _noteId) return -1
        if (b.id === _noteId) return 1
        return 0
      })
  }, [noteHasClient, noteInfo.data, clientNotes.data])

  const updateNotesViewedWithUndo = useUpdateNotesViewedWithUndo()
  React.useEffect(() => {
    if (!currentUser?.uid) return
    updateNotesViewedWithUndo.mutate({ currentUserId: currentUser.uid })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid])

  // Mutations
  const deleteClient = useDeleteClient({
    onSettled: (_, err) => {
      if (!err) {
        toast.info("Client deleted successfully")
        navigate("/notes")
      } else {
        toast.error("Error deleting client")
      }
    },
  })

  // @todo: Move this into ClientModal in next refactor.
  const [showClientModal, setShowClientModal] = React.useState(false)
  const [showDeleteClientModal, setDeleteClientModal] = React.useState(false)
  const [showEditClientModal, setShowEditClientModal] = React.useState(false)
  const [showClientDetailsModal, setShowClientDetailsModal] =
    React.useState(false)

  const [availableFields, setAvailableFields] = React.useState<Field[]>(
    () => clientFields
  )

  const isAllDeleted = React.useMemo(
    () => notes.length > 0 && notes.every((item) => !!item.deletedAt),
    [notes]
  )

  React.useEffect(() => {
    if (isAllDeleted && noteData?.clientId) {
      setDeleteClientModal(true)
    }
  }, [isAllDeleted, noteData?.clientId])

  const onClientRemoved = () => {
    if (notes.length === 1) {
      setDeleteClientModal(true)
    }
  }

  return (
    <>
      <div className="pb-20 bg-white h-full overflow-auto">
        <NoteHeader
          noteData={noteData!}
          setOpenClientDetailsModal={setShowClientDetailsModal}
          setOpenModal={setShowClientModal}
        />

        {notes.map((note, i) => (
          <NoteItem
            key={note.id}
            note={note}
            initialExpanded={i === 0}
            onClientRemoved={onClientRemoved}
          />
        ))}
      </div>

      <ConfirmationModal
        isOpen={showDeleteClientModal}
        onOpenChange={setDeleteClientModal}
        title="Delete Client?"
        description={PROMPTS.REMOVE_CLIENT}
        closeButton="No"
        confirmButton="Yes"
        onConfirm={() => {
          deleteClient.mutate({ clientId: noteData!.clientId, mode: "delete" })
        }}
      />

      <ClientModal
        noteId={noteData?.id || ""}
        isOpen={showClientModal}
        onOpenChange={setShowClientModal}
        refetch={() => {
          void noteInfo.refetch()
        }}
      />

      {noteData && (
        <>
          <ClientDetailsModal
            isOpen={showClientDetailsModal}
            setOpenClientDetailsModal={setShowClientDetailsModal}
            clientId={noteData?.clientId || ""}
            editButtonHandler={() => {
              setShowEditClientModal(true)
            }}
          />

          {noteData.clientId && noteId ? (
            <EditClientModal
              isOpen={showEditClientModal}
              setOpenModal={setShowEditClientModal}
              clientId={noteData.clientId}
              onSuccess={async () => {
                await noteInfo.refetch()
              }}
              addedFields={[]}
              setAvailableFields={setAvailableFields}
              availableFields={availableFields}
              onCloseAllModals={() => {
                setShowEditClientModal(false)
              }}
            />
          ) : null}
        </>
      )}
    </>
  )
}
