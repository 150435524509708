import React from "react"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/components/ui"

export interface AlertModalProps {
  open: boolean
  title: string
  description: string
  dismiss?: string
  confirm?: string
  onClose: () => void
  onConfirm: () => Promise<void> | void
  isDestructive?: boolean
}

/** @deprecated use ConfirmationModal */
export default function AlertModal({
  open,
  title,
  description,
  dismiss = "Cancel",
  confirm = "Sure",
  onClose,
  onConfirm,
  isDestructive,
}: AlertModalProps) {
  return (
    <AlertDialog
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose()
        }
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader className="space-y-0.5">
          <AlertDialogTitle>{title}</AlertDialogTitle>

          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>{dismiss}</AlertDialogCancel>

          <AlertDialogAction
            variant={isDestructive ? "destructive" : "default"}
            onClick={async (e) => {
              e.preventDefault()
              await onConfirm?.()
            }}
          >
            {confirm}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
