import { useState } from "react"
import type { MultiFactorResolver, PhoneMultiFactorInfo } from "firebase/auth"
import { ArrowLeftIcon } from "lucide-react"
import { useTranslation } from "react-i18next"
import Pincode from "~/components/Pincode"
import { Alert, AlertDescription, AlertTitle, Button } from "~/components/ui"
import { verifyUserEnrolled } from "~/lib/auth"

type Props = {
  verificationId: string
  resolver: MultiFactorResolver
  successfulLogin: () => Promise<void>
  handleCancel: () => void
  handleResendCode: () => Promise<void>
}

export function CodeLogin({
  verificationId,
  resolver,
  successfulLogin,
  handleCancel,
  handleResendCode,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const onResendCode = async () => {
    setError(undefined)
    await handleResendCode()
  }

  const handleSubmit = async (code: string) => {
    setError(undefined)
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      // Verify the code
      await verifyUserEnrolled({ verificationId, resolver }, code)

      await successfulLogin()
    } catch {
      setError("Failed to verify authentication code")
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="flex flex-col gap-4 mb-6 justify-center items-center">
      <h2 className="text-2xl font-bold">Enter verification code</h2>
      <p className="text-center">
        We sent your verification code to{" "}
        {(resolver.hints[0] as PhoneMultiFactorInfo)?.phoneNumber ??
          "your phone number"}
        .
        <Button
          className="font-bold text-md inline p-2"
          variant="link"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onResendCode}
        >
          Resend
        </Button>
      </p>

      {error !== undefined ? (
        <Alert variant="destructive">
          <AlertTitle className="text-[#c41c1c]">{error.toString()}</AlertTitle>
          <AlertDescription>
            <p className="text-[#c41c1c]">
              {t("loginPage.try.again", {
                support_email: "support@joy.day",
              })}
            </p>
          </AlertDescription>
        </Alert>
      ) : null}

      <div className="flex flex-row gap-1">
        <Pincode handleSubmit={handleSubmit} />
      </div>

      <Button
        className="py-1.5 px-4 -mt-2 text-sm text-black hover:text-primary"
        variant="link"
        size={"sm"}
        onClick={handleCancel}
      >
        <ArrowLeftIcon size={16} />
        Back to login
      </Button>
    </div>
  )
}
