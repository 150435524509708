import React, { Suspense, useEffect, useState } from "react"
import { Cross2Icon } from "@radix-ui/react-icons"
import { getDownloadURL, ref } from "firebase/storage"
import { storage } from "~/services/firebase"

const Player = React.lazy(() => import("./Player"))

interface AudioPlayerProps {
  storageId: string
  onClose: (show: boolean) => void
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ storageId, onClose }) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null)

  useEffect(() => {
    if (storageId) {
      const audioRef = ref(storage, storageId)

      getDownloadURL(audioRef)
        .then((url) => {
          setAudioUrl(url)
        })
        .catch((error) => {
          console.error("Error fetching audio file:", error)
        })
    }
  }, [storageId])

  return (
    <div className="flex flex-col p-3  rounded-md mb-4">
      <div className="flex flex-row justify-between m-1">
        <span>Audio</span>
        <button
          onClick={() => onClose(false)}
          className="flex items-center justify-center p-1 rounded hover:bg-gray-200 focus:outline-none"
        >
          <Cross2Icon className="w-5 h-5 text-gray-600" />
        </button>
      </div>
      <div className="flex-1">
        {audioUrl && (
          <Suspense fallback={<div>Loading player...</div>}>
            <Player src={audioUrl} />
          </Suspense>
        )}
      </div>
    </div>
  )
}

export default AudioPlayer
