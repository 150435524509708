import { useEffect, useRef, useState } from 'react'

// The interval defaults to 1000ms if no value is provided.
const useTimer = (interval: number = 1000) => {
  // State for storing the timer value.
  const [time, setTime] = useState<number>(0)

  // State for indicating whether the timer is running or not.
  const [isRunning, setIsRunning] = useState<boolean>(false)

  // Reference for storing the timer interval ID.
  const intervalRef = useRef<number | null>(null)

  const startTimer = () => {
    // Clear any existing timer before starting a new one.
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current)
    }

    // Set the running state to true and start the timer.
    setIsRunning(true)
    intervalRef.current = window.setInterval(() => {
      setTime((prevTime) => prevTime + interval)
    }, interval)
  }

  const stopTimer = () => {
    // Set the running state to false and clear the timer.
    setIsRunning(false)
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current)
    }
  }

  const resetTimer = () => {
    // Stop the timer and reset the timer value to 0.
    stopTimer()
    setTime(0)
  }

  // Clear the timer when the component using this hook is unmounted.
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current)
      }
    }
  }, [])

  // Expose the timer value, running state, and timer control functions.
  return {
    time,
    isRunning,
    startTimer,
    stopTimer,
    resetTimer,
  }
}

export default useTimer
