import * as React from "react"
import { AlertTriangle } from "lucide-react"
import { Button } from "~/components/ui/button"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../ui/drawer"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./alert-dialog"
import { useMediaQuery } from "./credenza"
import { cn } from "./utils"

const DESKTOP_SCREEN = "(min-width: 768px)"

type ConfirmationModalProps = {
  title?: React.ReactNode
  description?: React.ReactNode | (() => React.ReactNode)
  closeButton?: React.ReactNode
  confirmButton?: React.ReactNode
  isDestructive?: boolean

  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  onConfirm: () => void

  showIcon?: boolean
  className?: string
}

export function ConfirmationModal({
  title = "Are you sure?",
  description = "You will not be able to undo this action.",
  closeButton = "Cancel",
  confirmButton = "Sure",
  isDestructive = false,
  onConfirm,
  isOpen,
  onOpenChange,
  showIcon = false,
  className,
  children,
}: React.PropsWithChildren<ConfirmationModalProps>) {
  const isDesktop = useMediaQuery(DESKTOP_SCREEN)

  if (isDesktop) {
    return (
      <AlertDialog
        open={isOpen}
        onOpenChange={onOpenChange}
      >
        <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>

        <AlertDialogContent className={className}>
          <div className="sm:flex sm:items-start gap-4">
            {showIcon ? (
              <div
                className={cn(
                  "my-2 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
                  {
                    "bg-orange-100": !isDestructive,
                    "bg-destructive/20": isDestructive,
                  }
                )}
              >
                <AlertTriangle
                  className={cn("h-6 w-6", {
                    "text-orange-600": !isDestructive,
                    "text-destructive": isDestructive,
                  })}
                />
              </div>
            ) : null}

            <div className="flex-grow mt-3 text-center sm:mt-0 sm:text-left">
              <AlertDialogHeader className="space-y-0.5">
                <AlertDialogTitle>{title}</AlertDialogTitle>

                <AlertDialogDescription>
                  {typeof description === "function"
                    ? description()
                    : description}
                </AlertDialogDescription>
              </AlertDialogHeader>
            </div>
          </div>

          <AlertDialogFooter className="">
            <AlertDialogCancel>{closeButton}</AlertDialogCancel>

            <AlertDialogAction
              variant={isDestructive ? "destructive" : "default"}
              onClick={() => onConfirm?.()}
            >
              {confirmButton}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  return (
    <Drawer
      open={isOpen}
      onOpenChange={onOpenChange}
      shouldScaleBackground={false}
    >
      <DrawerTrigger asChild>{children}</DrawerTrigger>

      <DrawerContent
        indicator={false}
        className={cn(
          "border-0 rounded-2xl inset-x-4 bottom-4 after:!content-none",
          className
        )}
      >
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription>
            {typeof description === "function" ? description() : description}
          </DrawerDescription>
        </DrawerHeader>

        <DrawerFooter className="grid grid-cols-2">
          <DrawerClose asChild>
            <Button variant="ghost">{closeButton}</Button>
          </DrawerClose>
          <Button
            variant="destructive"
            onClick={() => onConfirm?.()}
          >
            {confirmButton}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
