import React from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { BaseControl } from "~/components/base/base-control"
import { Form, Input, SubmitButton } from "~/components/ui"
import { z, zodErrorMap } from "~/lib/zod"

type Props = {
  defaultEmail: string
  submitText?: string
  handleSubmit: (data: { email: string; password: string }) => Promise<void>
}

const loginFormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "This field is required" })
    .email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(1, { message: "This field is required" })
    .min(8, { message: "Password must be at least 8 characters" }),
})

export function LoginForm({
  defaultEmail,
  handleSubmit,
  submitText = "loginPage.general.sign.in",
}: Props) {
  const { t } = useTranslation()

  const form = useForm({
    resolver: zodResolver(loginFormSchema, { errorMap: zodErrorMap }),
    defaultValues: {
      email: defaultEmail || "",
      password: "",
    },
  })

  return (
    <Form
      form={form}
      className="space-y-6"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <BaseControl
        name="email"
        label={t("loginPage.general.email")}
        render={({ field }) => (
          <Input
            {...field}
            type="email"
            placeholder={t("loginPage.placeholder.email")}
          />
        )}
      />

      <BaseControl
        name="password"
        label={t("loginPage.general.password")}
        render={({ field }) => (
          <Input
            {...field}
            type="password"
            placeholder={"•••••••"}
          />
        )}
      />

      <SubmitButton
        type="submit"
        className="w-full"
        isSubmitting={form.formState.isSubmitting}
      >
        {t(submitText)}
      </SubmitButton>
    </Form>
  )
}
