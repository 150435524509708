import React from "react"
import UpgradeButton from "~/components/UpgradeButton"
import useSubscriptionHandler from "~/hooks/useSubscriptionHandler"
import {
  SubscriptionDataType,
  SubscriptionState,
  TrialType,
} from "~/types/subscriptionTypes"

const REDIRECTING = "Redirecting..."
const ACTIVATE_NOW = "Activate now"
const SUBSCRIBE_NOW = "Subscribe now"

const FreeNotesInfo: React.FC<{
  freeNotesRemaining: () => number | undefined
}> = ({ freeNotesRemaining }) => (
  <>
    {freeNotesRemaining() === 0 && (
      <p className="text-base font-medium">
        Activate your account to get unlimited notes
      </p>
    )}
  </>
)

interface NotesTrialInfoProps {
  freeNotesRemaining: () => number | undefined
  upgradeSubscription: () => void
  fetchingCheckoutSession: boolean
}

const NotesTrialInfo: React.FC<NotesTrialInfoProps> = ({
  freeNotesRemaining,
  upgradeSubscription,
  fetchingCheckoutSession,
}) => (
  <>
    <p className="text-sm font-medium">Your current plan</p>
    <p className="text-sm font-medium text-gray-500">Trial</p>
    <p className="text-sm font-medium">Free notes remaining</p>
    <p className="text-sm font-medium text-gray-500">{freeNotesRemaining()}</p>
    <FreeNotesInfo freeNotesRemaining={freeNotesRemaining} />

    <div className="flex justify-center">
      <UpgradeButton
        disabled={fetchingCheckoutSession}
        onClick={upgradeSubscription}
      >
        {fetchingCheckoutSession ? REDIRECTING : ACTIVATE_NOW}
      </UpgradeButton>
    </div>
  </>
)

interface FreeTrialInfoProps {
  subscriptionData: SubscriptionDataType | null
  freeNotesRemaining: () => number | undefined
  upgradeSubscription: () => void
  fetchingCheckoutSession: boolean
}

const FreeTrialInfo: React.FC<FreeTrialInfoProps> = ({
  subscriptionData,
  freeNotesRemaining,
  upgradeSubscription,
  fetchingCheckoutSession,
}) => (
  <>
    <p className="text-sm font-medium">Your current plan</p>
    <p className="text-sm font-medium text-gray-600">Trial</p>
    <p className="text-sm font-medium">Free until</p>
    <p className="text-sm font-medium text-gray-700">
      {subscriptionData?.stateDetails?.freeUntil
        ? subscriptionData?.stateDetails?.freeUntil
            .toDate()
            .toLocaleDateString()
        : ""}
    </p>

    <FreeNotesInfo freeNotesRemaining={freeNotesRemaining} />

    <div className="flex justify-center">
      <UpgradeButton
        disabled={fetchingCheckoutSession}
        onClick={upgradeSubscription}
      >
        {fetchingCheckoutSession ? REDIRECTING : ACTIVATE_NOW}
      </UpgradeButton>
    </div>
  </>
)

const SubscribedInfo: React.FC = () => (
  <>
    <p className="text-sm font-medium">Your current plan</p>
    <p className="text-sm font-medium text-gray-500">Paid</p>
    <p className="text-sm font-medium">Billing Cycle</p>
    <p className="text-sm font-medium text-gray-500">Monthly</p>
    <div>
      <br />
      <p className="text-sm font-medium text-center">
        Contact support to update or cancel your subscription.
      </p>
    </div>
  </>
)

interface UnSubscribedInfoProps {
  freeNotesRemaining: () => number | undefined
  upgradeSubscription: () => void
  fetchingCheckoutSession: boolean
}
const UnSubscribedInfo: React.FC<UnSubscribedInfoProps> = ({
  freeNotesRemaining,
  upgradeSubscription,
  fetchingCheckoutSession,
}) => (
  <>
    <p className="text-sm font-medium">No active plan</p>
    <p className="text-sm font-medium">Free notes remaining</p>
    <p className="text-sm font-medium text-gray-500">{freeNotesRemaining()}</p>

    <FreeNotesInfo freeNotesRemaining={freeNotesRemaining} />

    <div className="flex justify-center">
      <UpgradeButton
        disabled={fetchingCheckoutSession}
        onClick={upgradeSubscription}
      >
        {fetchingCheckoutSession ? REDIRECTING : SUBSCRIBE_NOW}
      </UpgradeButton>
    </div>
  </>
)

// This component is used to display the subscription details in the account page
function SubscriptionsCard() {
  const {
    isLoading,
    freeNotesRemaining,
    subscriptionData,
    upgradeSubscription,
    fetchingCheckoutSession,
    showSubscriptionInfo,
  } = useSubscriptionHandler()

  if (!showSubscriptionInfo()) {
    return null
  }

  return (
    <div className="bg-white shadow rounded-lg py-4 gap-y-3 gap-x-4">
      <h3 className="text-base leading-6 font-medium text-gray-900 px-4">
        Subscription
      </h3>

      <hr className="border-t border-gray-200 mt-4"></hr>

      <div className="px-4 py-4 sm:px-6">
        {isLoading && (
          <p className="text-sm mb-2 font-medium ">
            Fetching subscription details...
          </p>
        )}

        {subscriptionData?.state === SubscriptionState.TRIAL &&
          subscriptionData?.stateDetails?.trialType ===
            TrialType.CONSUME_NOTES && (
            <NotesTrialInfo
              freeNotesRemaining={freeNotesRemaining}
              upgradeSubscription={upgradeSubscription}
              fetchingCheckoutSession={fetchingCheckoutSession}
            />
          )}

        {subscriptionData?.state === SubscriptionState.TRIAL &&
          subscriptionData?.stateDetails?.trialType === TrialType.FREE && (
            <FreeTrialInfo
              subscriptionData={subscriptionData}
              freeNotesRemaining={freeNotesRemaining}
              upgradeSubscription={upgradeSubscription}
              fetchingCheckoutSession={fetchingCheckoutSession}
            />
          )}

        {subscriptionData?.state === SubscriptionState.SUBSCRIBED && (
          <SubscribedInfo />
        )}

        {subscriptionData?.state === SubscriptionState.UNSUBSCRIBED && (
          <UnSubscribedInfo
            freeNotesRemaining={freeNotesRemaining}
            upgradeSubscription={upgradeSubscription}
            fetchingCheckoutSession={fetchingCheckoutSession}
          />
        )}
      </div>
    </div>
  )
}

export default SubscriptionsCard
