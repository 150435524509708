import { Timestamp } from 'firebase/firestore'

export enum SubscriptionState {
  TRIAL = 'trial',
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum TrialType {
  FREE = 'free',
  CONSUME_NOTES = 'consume_notes',
}

export type NoteData = {
  allowed: number
  created: number
}

export type SubscriptionStateDetails = {
  trialType: TrialType | null
  freeUntil: Timestamp | null
  status: string | null
}

export type SubscriptionDataType = {
  updatedAt: number | null // Seconds since epoch
  notes: NoteData | null
  state: SubscriptionState
  stateDetails: SubscriptionStateDetails | null
}
