// CountrySelector.tsx
import * as React from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui"
import { allCountries } from "~/utils/countries"

interface CountrySelectorProps {
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
  name?: string
}

const CountrySelector = React.forwardRef<
  HTMLButtonElement,
  CountrySelectorProps
>(({ value, onChange, onBlur, name }, ref) => {
  return (
    <Select
      value={value}
      onValueChange={onChange}
      name={name}
    >
      <SelectTrigger
        ref={ref}
        onBlur={onBlur}
        className="w-full"
      >
        <SelectValue placeholder="Select country..." />
      </SelectTrigger>

      <SelectContent>
        {allCountries.map((item) => (
          <SelectItem
            key={item.code}
            value={item.code}
          >
            <CountryOption
              code={item.code}
              name={item.name}
            />
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
})

CountrySelector.displayName = "CountrySelector"

interface CountryOptionProps {
  code: string
  name: string
}

const CountryOption: React.FC<CountryOptionProps> = ({ code, name }) => (
  <span className="flex items-center leading-none gap-3">
    {code !== "--" && (
      <img
        width="20"
        height="20"
        srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
        loading="lazy"
        alt=""
      />
    )}
    <span className="flex-nowrap line-clamp-1 overflow-ellipsis">{name}</span>
  </span>
)

export default CountrySelector
