import React from 'react'

import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/components/ui'
import { ChevronDown, MessageSquareText } from 'lucide-react'
import { Language, supportedDictationLanguages } from '~/utils/languages'

interface LanguageSelectorProps {
  disabled?: boolean
  selectedLanguageCode: string
  onLanguageSelect: (name: string, code: string) => void
}

export default function LanguageSelector({
  disabled,
  selectedLanguageCode,
  onLanguageSelect,
}: LanguageSelectorProps) {
  const selectedLangObj =
    supportedDictationLanguages.find(
      (lang: Language) => lang.code === selectedLanguageCode
    ) || supportedDictationLanguages[0]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          type="button"
          size="sm"
          variant="ghost"
          disabled={disabled}
          className="ring-offset-accent"
          title="Dictation language"
        >
          <MessageSquareText className="size-4" />
          <span>{selectedLangObj.name}</span>
          <ChevronDown className="size-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        {supportedDictationLanguages.map((lang: Language) => (
          <DropdownMenuCheckboxItem
            key={lang.code}
            checked={lang.code === selectedLanguageCode}
            onSelect={() => onLanguageSelect(lang.name, lang.code)}
          >
            {lang.name}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
