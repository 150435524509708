import {
  QueryClient,
  type DefaultError,
  type QueryKey,
  type UseInfiniteQueryOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from "@tanstack/react-query"

export type QueryParameter<
  queryFnData = unknown,
  error = DefaultError,
  data = queryFnData,
  queryKey extends QueryKey = QueryKey,
> = {
  reactQuery?:
    | Omit<
        UseQueryOptions<queryFnData, error, data, queryKey>,
        "queryFn" | "queryHash" | "queryKey"
      >
    | undefined
}

export type InfiniteQueryParameter<
  queryFnData = unknown,
  error = DefaultError,
  data = queryFnData,
  queryData = queryFnData,
  queryKey extends QueryKey = QueryKey,
> = {
  reactQuery?:
    | Omit<
        UseInfiniteQueryOptions<queryFnData, error, data, queryData, queryKey>,
        | "queryFn"
        | "queryKey"
        | "queryHash"
        | "initialData"
        | "getNextPageParam"
      >
    | undefined
}

export type MutationOptions<TVariables = any | void, TData = any> = Omit<
  UseMutationOptions<TData, Error, TVariables>,
  "mutationFn" | "mutationKey"
>

const MAX_RETRIES = 1

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: (failureCount) => failureCount < MAX_RETRIES,
    },
  },
})
