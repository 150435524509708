import typography from "@tailwindcss/typography";

/** @type {import('tailwindcss').Config} */
export const content = ['./src/**/*.{js,ts,jsx,tsx}'];
export const darkMode = 'false';
export const theme = {
  extend: {
    spacing: {
      'safe-bottom': 'env(safe-area-inset-bottom)',
    },
    backdropBlur: {
      'md-plus': '14px',
    },
    lineHeight: {
      '5-plus' : '1.375rem',
    },
    borderRadius: {
      '2xl-plus': '1.125rem',
      'lg-plus': '0.625rem',
      // shadcn
      lg: `var(--radius)`,
      md: `calc(var(--radius) - 3px)`,
      sm: "calc(var(--radius) - 6px)",
    },
    fontFamily: {
      platypi: ['Platypi', 'sans-serif'],
      sans: ['Inter', 'SF Pro', 'sans-serif'],
      mono: ['Archivo', 'monospace'],
      archivo: ['Archivo']
    },
    width: {
      'fill-available': '-webkit-fill-available',
    },
    screens: {
      x425: { min: '425px' },
    },
    colors: {
      'primary-black': '#0F0F0F',
      'situational-error': '#FF0D56',
      'situational-success': '#37BA73',
      'transparent_gray': '#F0F0F0A0',
      'light_gray': '#F0F0F0',
      'ocean-blue': '#0B6BCB',
      'fiord': '#555E68',
      'medium-grey': {
          300: '#837675',
          350: '#EEEFEE',
          400: '#EAEAEA',
          500: '#7D7E7F',
          600: '#F3F4F6',
        },
        'primary-cream': {
          300: '#fff3df',
          500: '#E8DBC8',
        },
        'primary-soft-green': {
          500: '#9fe0b7',
        },
        'purple-haze': {
          400: '#523280',
          500: '#573085',
        },
        'normal-black': {
          300: '#1B1B1B',
          400: '#111928'
        },
        cream: {
          500: '#F0F0F0',
        },
        bright_coral: {
          400: '#FF5754',
          500: '#FF665C',
          600: '#b24740',
        },
        bright_lilac: {
          500: '#E6ADFF',
        },
        soft_green: {
          500: '#ADDEBA',
          600: '#799b82',
        },
        // shadcn
      border: "hsl(var(--border))",
      input: "hsl(var(--input))",
      ring: "hsl(var(--ring))",
      background: "hsl(var(--background))",
      foreground: "hsl(var(--foreground))",
      primary: {
        DEFAULT: "hsl(var(--primary))",
        foreground: "hsl(var(--primary-foreground))",
      },
      secondary: {
        DEFAULT: "hsl(var(--secondary))",
        foreground: "hsl(var(--secondary-foreground))",
      },
      destructive: {
        DEFAULT: "hsl(var(--destructive))",
        foreground: "hsl(var(--destructive-foreground))",
      },
      muted: {
        DEFAULT: "hsl(var(--muted))",
        foreground: "hsl(var(--muted-foreground))",
      },
      accent: {
        DEFAULT: "hsl(var(--accent))",
        foreground: "hsl(var(--accent-foreground))",
      },
      popover: {
        DEFAULT: "hsl(var(--popover))",
        foreground: "hsl(var(--popover-foreground))",
      },
      card: {
        DEFAULT: "hsl(var(--card))",
        foreground: "hsl(var(--card-foreground))",
      },
    },
    height: {
      3: '0.25rem',
    },
    utilities: {
      '.text-align-last-center': {
        'text-align-last': 'center',
      },
    },
    keyframes: {
      "accordion-down": {
        from: { height: 0 },
        to: { height: "var(--radix-accordion-content-height)" },
      },
      "accordion-up": {
        from: { height: "var(--radix-accordion-content-height)" },
        to: { height: 0 },
      },
      "caret-blink": {
        "0%,70%,100%": { opacity: "1" },
        "20%,50%": { opacity: "0" },
      },
      tada: {
        "0%": {
            transform: "scale3d(1, 1, 1)",
        },
        "10%, 20%": {
            transform: "scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)",
        },
        "30%, 50%, 70%, 90%": {
            transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)",
        },
        "40%, 60%, 80%": {
            transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)",
        },
        "100%": {
            transform: "scale3d(1, 1, 1)",
        },
      },
    },
    animation: {
      "caret-blink": "caret-blink 1.25s ease-out infinite",
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
      tada: 'tada 1s ease-in-out 0.25s 1',
    },
    backgroundImage:{
      'notes-landing': "url('assets/images/notes_landing_background.jpg')",
      'notes-landing-dark': "url('https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831')",
    },
  },
};

export const plugins = [
  typography,
];
