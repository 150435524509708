import * as React from "react"
import { EyeIcon, EyeOffIcon } from "lucide-react"
import { Button } from "./button"
import { Input, type InputProps } from "./input"

export type PasswordInputProps = Omit<InputProps, "type"> & {
  initialShowPassword?: boolean
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ initialShowPassword = false, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(initialShowPassword)

    return (
      <div className="relative z-[1] inline-flex w-full">
        <Input
          {...props}
          ref={ref}
          type={showPassword ? "text" : "password"}
        />

        <Button
          type="button"
          title={"Toggle password"}
          size="icon"
          variant="link"
          className="absolute right-0 top-0 h-full z-[2]"
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault()
            setShowPassword((prev) => !prev)
          }}
        >
          {showPassword ? (
            <EyeOffIcon
              size={20}
              className="size-5 text-neutral-500"
            />
          ) : (
            <EyeIcon
              size={20}
              className="size-5 text-neutral-500"
            />
          )}
        </Button>
      </div>
    )
  }
)
PasswordInput.displayName = "PasswordInput"

export { PasswordInput }
