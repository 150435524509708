import React from "react"
import { Slot } from "@radix-ui/react-slot"
import { ControllerProps, FieldValues, useFormContext } from "react-hook-form"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui"

type BaseControlProps<TFieldValues extends FieldValues = FieldValues> =
  ControllerProps<TFieldValues> & {
    label?: React.ReactNode
    description?: React.ReactNode
    errorMessage?: React.ReactNode
    required?: boolean
    className?: string
  }

const BaseControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  BaseControlProps
>((props, ref) => {
  const {
    render,
    label,
    description,
    errorMessage,
    className,
    required = false,
    ...fieldProps
  } = props

  const form = useFormContext()

  return (
    <FormField
      {...fieldProps}
      control={form.control}
      render={({ field, fieldState, formState }) => {
        return (
          <FormItem className={className}>
            {label ? (
              <FormLabel>
                {label}
                {required && <i className="text-red-500">*</i>}
              </FormLabel>
            ) : null}

            <FormControl ref={ref}>
              {render({ field, fieldState, formState })}
            </FormControl>

            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage>{errorMessage}</FormMessage>
          </FormItem>
        )
      }}
    />
  )
})
BaseControl.displayName = "BaseControl"

export { BaseControl }
