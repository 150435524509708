import { getBlob, ref } from "firebase/storage"
import { Note, NoteStatus } from "~/pages/Notes/types" // TODO : Move these types to a better location
import { storage } from "~/services/firebase"
import { downloadAudioBlob, LocalRecordings } from "~/utils/recordings"

const downloadLocalRecording = async (note: Note) => {
  const local = new LocalRecordings()
  const audioBlob = await local.get(note.id)
  if (audioBlob) {
    const filename =
      note.title + audioBlob.type.replace(/^audio\/([a-zA-Z0-9]+).*$/, ".$1")
    downloadAudioBlob(audioBlob, filename)
  } else {
    throw new Error("No local recording found for the note")
  }
}

const downloadServerRecording = async (note: Note) => {
  if (note?.storageRef !== undefined) {
    const storageRef = ref(storage, note?.storageRef || "")
    const audioBlob = await getBlob(storageRef)
    downloadAudioBlob(audioBlob, storageRef.name)
  } else {
    throw new Error("No storage ref found for the note")
  }
}

export const downloadRecording = async (note: Note) => {
  if (note?.status === NoteStatus.Uploading) {
    await downloadLocalRecording(note)
  } else if (note?.status === NoteStatus.Processing) {
    await downloadServerRecording(note)
  } else if (note?.status === NoteStatus.Error) {
    // Try to download locally
    try {
      await downloadLocalRecording(note)
    } catch (error) {
      console.log(error)
      // Try to download from server
      await downloadServerRecording(note)
    }
  }
}
