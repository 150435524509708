import * as React from "react"
import { Checkbox, Label } from "~/components/ui"

export interface InlineCheckboxProps
  extends React.ComponentPropsWithoutRef<typeof Checkbox> {
  label: React.ReactNode
}

export const InlineCheckbox = React.forwardRef<
  React.ElementRef<typeof Checkbox>,
  InlineCheckboxProps
>(({ label, ...props }, ref) => (
  <div className="flex items-center space-x-2">
    <Checkbox
      ref={ref}
      {...props}
    />
    <Label htmlFor={props?.id}>{label}</Label>
  </div>
))
InlineCheckbox.displayName = "InlineCheckbox"
