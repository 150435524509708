import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Credenza as Dialog,
  CredenzaBody as DialogBody,
  CredenzaContent as DialogContent,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
} from "~/components/ui"
import { useClientById } from "~/hooks/firestore/useClients"

interface ClientData {
  name?: string
  phone?: string
  email?: string

  [key: string]: string | undefined
}

interface ClientDetailsModalProps {
  isOpen: boolean
  clientId: string
  editButtonHandler: () => void
  setOpenClientDetailsModal: (x: boolean) => void
}

const omit = (obj: any, keys: string[]) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)))

const ClientDetailsModal: React.FC<ClientDetailsModalProps> = ({
  isOpen,
  setOpenClientDetailsModal,
  clientId,
  editButtonHandler,
}) => {
  const client = useClientById({
    clientId,
    reactQuery: {
      enabled: isOpen && Boolean(clientId),
    },
  })

  const clientData = omit(client.data ?? {}, ["id"]) as ClientData

  return (
    <Dialog
      open={isOpen}
      onOpenChange={setOpenClientDetailsModal}
    >
      <DialogContent
        aria-describedby=""
        className="bg-primary-cream-300 h-[80%] md:h-auto border-0"
      >
        <DialogHeader className="flex flex-row items-center gap-4 justify-between">
          <DialogTitle className="sr-only">Client Info</DialogTitle>

          <Button
            variant="secondary"
            onClick={(event) => {
              event.preventDefault()
              // setOpenClientDetailsModal(false)
              editButtonHandler()
            }}
          >
            Edit
          </Button>
        </DialogHeader>

        <DialogBody className="flex-1 overflow-y-auto">
          <div className="">
            <p className="font-bold text-primary-black text-[1.75rem] font-platypi capitalize">
              {clientData.name}
            </p>
          </div>

          {clientData.phone && (
            <div className="pb-4">
              <a
                href={`tel:${clientData.phone}`}
                className="font-medium flex justify-center px-5 py-[0.875rem] rounded-[0.625rem] text-lg text-white bg-primary-black font-archivo"
              >
                {clientData.phone}
              </a>
            </div>
          )}

          {clientData.email && (
            <div className="pb-4">
              <a
                href={`mailto:${clientData.email}`}
                className="font-medium flex justify-center px-5 py-[0.875rem] rounded-[0.625rem] text-lg text-white bg-primary-black font-archivo"
              >
                {clientData.email}
              </a>
            </div>
          )}

          {Object.keys(clientData).map(
            (key) =>
              key !== "name" &&
              key !== "phone" &&
              key !== "email" && (
                <div
                  className="pb-2"
                  key={key}
                >
                  <p className="font-medium flex justify-center px-5 py-[0.875rem] rounded-[0.625rem] text-lg text-primary-black font-archivo">
                    {clientData[key]}
                  </p>
                </div>
              )
          )}
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

ClientDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenClientDetailsModal: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  editButtonHandler: PropTypes.func.isRequired,
}

export default ClientDetailsModal
