import React, { useContext, useEffect, useState } from "react"
import * as firebaseAuth from "firebase/auth"
import { auth } from "~/services/firebase"

export interface AuthContextType {
  currentUser: firebaseAuth.User | null
}

export const AuthContext = React.createContext<AuthContextType>({
  currentUser: null,
})

export function useAuth(): AuthContextType {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<firebaseAuth.User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribeIdToken = auth.onIdTokenChanged((user) => {
      setCurrentUser(user)
    })

    const unsubscribeAuthState = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return () => {
      unsubscribeIdToken()
      unsubscribeAuthState()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
