import type { QueryClient } from "@tanstack/react-query"
import type { Subscription } from "rxjs"

const clientCacheSubscriptionsKey = ["__activeSubscriptions__"]
const defaultKey = Symbol("__default__")

type SubscriptionStorageItem = Map<string | typeof defaultKey, Subscription>
type SubscriptionStorage = Map<string, SubscriptionStorageItem>

/**
 * Stores subscription by its key and `cursor` in the clientCache.
 */
export function storeSubscription(
  queryClient: QueryClient,
  hashedSubscriptionKey: string,
  subscription: Subscription,
  cursor?: string | number
) {
  const activeSubscriptions: SubscriptionStorage =
    queryClient.getQueryData(clientCacheSubscriptionsKey) || new Map()

  let newSubscriptionValue: SubscriptionStorageItem
  const previousSubscription = activeSubscriptions.get(hashedSubscriptionKey)

  const key = cursor ? `${cursor}` : defaultKey

  if (previousSubscription) {
    // Unsubscribe from previous subscription
    if (previousSubscription.has(key)) {
      console.info(
        "[storeSubscription] Unsubscribing from previous subscription",
        hashedSubscriptionKey
      )
      previousSubscription.get(key)?.unsubscribe()
    }

    previousSubscription.set(key, subscription)
    newSubscriptionValue = previousSubscription
  } else {
    newSubscriptionValue = new Map([[key, subscription]])
  }

  activeSubscriptions.set(hashedSubscriptionKey, newSubscriptionValue)

  queryClient.setQueryData(clientCacheSubscriptionsKey, activeSubscriptions)
}

/**
 * Removes stored subscription by its key and `pageParam` from the clientCache.
 */
export function cleanupSubscription(
  queryClient: QueryClient,
  hashedSubscriptionKey: string,
  cursor?: string | number
) {
  const activeSubscriptions: SubscriptionStorage =
    queryClient.getQueryData(clientCacheSubscriptionsKey) || new Map()

  const subscription = activeSubscriptions.get(hashedSubscriptionKey)

  if (!subscription) return

  if (cursor === undefined) {
    subscription.forEach((subscription) => {
      subscription.unsubscribe()
    })
    activeSubscriptions.delete(hashedSubscriptionKey)
  } else {
    subscription.get(`${cursor}`)?.unsubscribe()
    subscription.delete(`${cursor}`)
    activeSubscriptions.set(hashedSubscriptionKey, subscription)
  }

  queryClient.setQueryData(clientCacheSubscriptionsKey, activeSubscriptions)
}

export function cleanupActiveSubscriptions(queryClient: QueryClient) {
  const activeSubscriptions: SubscriptionStorage =
    queryClient.getQueryData(clientCacheSubscriptionsKey) || new Map()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_, subscription] of activeSubscriptions) {
    subscription.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }
}
