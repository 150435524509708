import React, { useEffect, useState } from "react"
import BoxContainer from "~/components/BoxContainer"
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { logEvent } from "firebase/analytics"
import { Link, useNavigate } from "react-router-dom"
import { resendVerificationEmail } from "~/lib/auth"
import { analytics, auth } from "~/services/firebase"

export default function VerifyEmail() {
  const [open, setOpen] = useState<boolean>(false)
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const handleResendVerificationEmail = async () => {
    if (!currentUser) return
    try {
      await resendVerificationEmail(currentUser)
      logEvent(analytics, "resend_verification_email")
      setOpen(true)
    } catch (error) {
      toast.error(
        "Failed to resend verification email, please try again later."
      )
      console.error("Error resending verification email:", error)
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          console.log("email verified")
          navigate("/", { replace: true })
        }
      }
    })
    return unsubscribe
  }, [navigate])

  return (
    <BoxContainer>
      <div>
        <h2 className="text-2xl font-bold leading-9">
          Almost There! Just One More Step.
        </h2>
        <p className="mt-2 mb-6">
          We&apos;ve sent a verification link to your email. To complete your
          sign-up please click on that link.
        </p>
        <p className="mt-2 mb-6">
          If you don&apos;t see the email, check your spam folder or hit the
          &apos;Resend&apos; button below.
        </p>
      </div>

      <Button
        className="font-bold"
        onClick={handleResendVerificationEmail}
      >
        Resend email
      </Button>

      <p className="text-center text-[14px] text-[#555E68]">
        Having trouble? Drop us a line at support@joy.day
      </p>

      <div className="flex justify-center items-center">
        <Link
          className="underline underline-always"
          to="/login"
        >
          Already verified?
        </Link>
      </div>

      <AlertDialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open)
          if (!open) {
            navigate("/")
          }
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Email sent</AlertDialogTitle>
            <AlertDialogDescription>
              We sent you a new verification email. Please click on the link in
              the email to verify your address.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Continue</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </BoxContainer>
  )
}
