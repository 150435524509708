import { useEffect, useState } from "react"
import { initializePaddle, type Paddle } from "@paddle/paddle-js"
import { doc, onSnapshot } from "firebase/firestore"
import { toast } from "~/components/ui"
import {
  isUserAllowedToRecordNewNote,
  shouldShowSubscriptionInfo,
} from "~/config/subscriptionPolicies"
import { useAuth } from "~/context/AuthContext"
import { db } from "~/services/firebase"
import { SubscriptionDataType } from "~/types/subscriptionTypes"
import { useUserProfile } from "./useUserProfile"

interface ProductItem {
  priceId: string
  quantity: number
}

const useSubscriptionHandler = () => {
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionDataType>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { currentUser } = useAuth()
  const [userProfile] = useUserProfile()
  useState<boolean>(false)
  const [fetchingCheckoutSession, setFetchingCheckoutSession] =
    useState<boolean>(false)

  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState<Paddle>()

  const freeNotesRemaining = (): number | undefined => {
    if (
      subscriptionData?.notes?.allowed === undefined ||
      subscriptionData?.notes?.created === undefined
    ) {
      return undefined
    }
    const remainingNotes = Math.max(
      subscriptionData.notes.allowed - subscriptionData.notes.created,
      0
    )
    return remainingNotes
  }

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    if (paddle !== undefined) {
      // only init paddle once per page
      return
    }

    initializePaddle({
      environment: isProdPayment() ? "production" : "sandbox",
      token: isProdPayment()
        ? "live_10055b202e07d93a33cdbc738f4"
        : "test_951f0f9f16403c147812972419c",
      checkout: {
        settings: {
          showAddDiscounts: false,
        },
      },
    })
      .then((paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          setPaddle(paddleInstance)
        }
      })
      .catch((error) => {
        console.error("Error initializing Paddle:", error)
        toast.error(
          "Could not initiate Paddle used for subscriptions.Please reload the page or contact support."
        )
      })
  }, [paddle])

  useEffect(() => {
    setIsLoading(true)
    // Listen to subscription document changes
    const subscriptionDocRef = doc(
      db,
      `users/${currentUser?.uid}/profile/subscription`,
      ""
    )
    const unsubscribe = onSnapshot(
      subscriptionDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data() as SubscriptionDataType
          setSubscriptionData(data)
        }
        setIsLoading(false)
      },
      () => {
        setSubscriptionData(undefined)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
      }
    )
    return () => unsubscribe()
  }, [currentUser])

  function isProdPayment(): boolean {
    return import.meta.env.MODE === "production"
  }

  function getProductItems(): ProductItem[] {
    // Only return the products for prod in prod, this means both
    // dev and staging has "development" products/pricing
    return isProdPayment()
      ? [{ priceId: "pri_01ja7ncffr9sjavk9bwzzp919v", quantity: 1 }]
      : [{ priceId: "pri_01j93kesz2jyg9jzek8qdm2qxw", quantity: 1 }]
  }

  // Initiates a checkout session in a new tab
  const upgradeSubscription = () => {
    if (!currentUser || !currentUser.email) {
      toast.error("Please log in to upgrade your account.")
      return
    }
    if (fetchingCheckoutSession) {
      return
    }
    setFetchingCheckoutSession(true)

    try {
      paddle?.Checkout.open({
        items: getProductItems(),
        customData: {
          firebase_uid: currentUser.uid,
        },
        customer: {
          email: currentUser.email,
        },
      })
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Failed to setup checkout session") {
          toast.error(
            "Checkout session creation failed. Please try again or contact support."
          )
          return
        }
      }
      toast.error(
        "An unexpected error occurred. Please try again or contact support."
      )
      console.error("Error upgrading account:", error)
    } finally {
      setFetchingCheckoutSession(false)
    }
  }

  // Determine if the user is allowed to record notes
  const allowNotesRecording = () => {
    return isUserAllowedToRecordNewNote(userProfile, subscriptionData)
  }

  // Returns true if subscription info should be shown
  const showSubscriptionInfo = () => {
    return shouldShowSubscriptionInfo(userProfile, subscriptionData)
  }

  return {
    showSubscriptionInfo,
    allowNotesRecording,
    isLoading,
    subscriptionData,
    freeNotesRemaining,
    upgradeSubscription,
    fetchingCheckoutSession,
  }
}

export default useSubscriptionHandler
