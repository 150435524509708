import { useEffect, useMemo, useState } from "react"
import { Widget } from "@typeform/embed-react"
import Lottie from "lottie-react"
import confettiAnimation from "~/assets/animations/confetti.json"
import CatInHammock from "~/assets/images/cat_in_hammock.jpg"
import CatTyping from "~/assets/images/cat_typing.gif"
import { Dialog, DialogContent } from "~/components/ui"
import { useUserJourney } from "~/hooks/useUserJourney"
import { useUserProfile } from "~/hooks/useUserProfile"
import { useUserStatistics } from "~/hooks/useUserStatistics"
import { Note, NoteStatus } from "./types"

interface NotesOnboardingProps {
  notes: Note[]
}

const NotesOnboarding: React.FC<NotesOnboardingProps> = ({ notes }) => {
  const [userStatistics, , isPendingStatistics] = useUserStatistics()
  const [userJourney, updateUserJourney, isPendingJourney] = useUserJourney()
  const [userProfile] = useUserProfile()
  const [confettiAnimationFinished, setConfettiAnimationFinished] =
    useState<boolean>(false)

  const shouldOpenSurveyDialog = useMemo(() => {
    return (
      !userJourney?.onboardingCompleted &&
      userJourney?.noteDetailsViewed &&
      (userStatistics?.totalSavedNotes ?? 0) > 3
    )
  }, [userJourney, userStatistics])

  const firstNoteStatus = useMemo(() => {
    return (
      notes.length > 0 &&
      [NoteStatus.Processing, NoteStatus.Uploading].includes(notes[0]?.status)
    )
  }, [notes])

  useEffect(() => {
    const { state } = userJourney ?? {}
    if (state === "Registered") {
      updateUserJourney({ state: "Onboarding" })
    }
    return () => {
      // If first note is completed, update user journey
      if (userJourney?.noteDetailsViewed && !userJourney?.firstNoteCompleted) {
        updateUserJourney({ firstNoteCompleted: true })
      }
      // If user has saved 2 notes, update user journey that transforms are introduced
      if (
        userStatistics?.totalSavedNotes === 2 &&
        !userJourney?.transformsIntroduced
      ) {
        updateUserJourney({ transformsIntroduced: true })
      }
    }
  }, [userStatistics, userJourney, updateUserJourney])

  // Early return if user statistics or user journey is not available
  if (
    isPendingStatistics ||
    isPendingJourney ||
    !userStatistics ||
    !userJourney ||
    (userStatistics?.totalSavedNotes ?? 0) > 5
  ) {
    return null
  }

  return (
    <>
      {
        /* Survey dialog  */
        <Dialog
          open={shouldOpenSurveyDialog}
          onOpenChange={() => {
            updateUserJourney({
              onboardingCompleted: true,
            })
          }}
        >
          <DialogContent className="p-0 w-full h-full max-h-[600px] ">
            <Widget
              id="bQMFOI6Q"
              hidden={{
                email: userProfile?.email ? userProfile.email : "anonymous",
              }}
              inlineOnMobile
              style={{ width: "100%", height: "100%" }}
              onSubmit={() => {
                updateUserJourney({
                  surveyAnswered: true,
                  onboardingCompleted: true,
                })
              }}
            />
          </DialogContent>
        </Dialog>
      }

      {
        /* First note recorded */
        userStatistics?.totalSavedNotes < 2 &&
          !userJourney?.noteDetailsViewed && (
            <div className="px-5 py-8 flex flex-col items-center rounded-xl my-4 gap-2 bg-[#F5DFFF] shadow">
              {firstNoteStatus ? (
                <>
                  <p>Working on your note!</p>
                  <div className="flex items-center justify-center">
                    <img
                      width="250px"
                      src={CatTyping}
                      alt="cat typing..."
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>Looks like your note is ready!</p>
                  <p> Click on it above to open.</p>
                  <div className="flex items-center justify-center">
                    <img
                      width="250px"
                      src={CatInHammock}
                      alt="cat relaxing..."
                    />
                  </div>
                </>
              )}
            </div>
          )
      }

      {
        /* First note viewed and completed */
        userStatistics?.totalSavedNotes === 1 &&
          userJourney?.noteDetailsViewed &&
          userJourney?.firstNoteCompleted && (
            <div className="p-5 flex flex-col items-center rounded-xl py-4 my-4 gap-2 bg-[#F5DFFF] shadow">
              <h4 className="font-platipy text-lg">
                Yay, you&apos;ve completed your first note!
              </h4>
              <p className="text-center">
                Now click on the{" "}
                <strong className="text-[#FF665C]">New note</strong> button
                above to record your next one.
              </p>
              <div
                style={{
                  display: confettiAnimationFinished ? "none" : "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                {!userJourney.firstNoteConfettiShown && (
                  <Lottie
                    animationData={confettiAnimation}
                    loop={false}
                    onComplete={() => {
                      setConfettiAnimationFinished(true)
                      updateUserJourney({
                        firstNoteConfettiShown: true,
                      })
                    }}
                  />
                )}
              </div>
            </div>
          )
      }

      {
        /* Second note recorded */
        userStatistics?.totalSavedNotes === 2 &&
          !userJourney?.transformsIntroduced && (
            <div className="p-5 flex flex-col items-center rounded-xl py-4 my-4 gap-2 bg-[#F5DFFF] shadow">
              <h4 className="font-platipy text-lg">Nice!</h4>
              <div className="flex flex-col">
                <p className="md:text-center"></p>
                <p className="md:text-center text-lg">
                  Use <strong>Set client</strong> to organize your notes.
                </p>
                <p className="md:text-center text-lg">
                  You can also apply <strong>smart formats</strong> to it.
                </p>
                <p className="md:text-center text-lg mt-2">
                  Click on the note you just recorded to try it out!
                </p>
              </div>
            </div>
          )
      }
    </>
  )
}

export default NotesOnboarding
