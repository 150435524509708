import React from "react"
import { Cross1Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { Button, Input } from "./ui"

interface SearchProps {
  isFocused: boolean
  searchClient: string
  setSearchClient: (name: string) => void
  setIsFocused: (status: boolean) => void
}
const SearchField: React.FC<SearchProps> = ({
  isFocused,
  searchClient,
  setSearchClient,
  setIsFocused,
}) => {
  const onCancel = () => {
    setSearchClient(""), setIsFocused(false)
  }

  return (
    <div className={`relative w-full ${isFocused ? "mt-2" : ""}  `}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon
          className="h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
      </div>
      <Input
        type="text"
        value={searchClient}
        onChange={(e) => setSearchClient(e.target.value)}
        placeholder="Search clients"
        className="pl-10 pr-10 py-2 border rounded-lg w-full bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-black opacity-50 focus:opacity-100 focus:border-transparent transition-all"
        onFocus={() => {
          setIsFocused(true)
        }}
      />
      {isFocused && (
        <Button
          type="button"
          onClick={onCancel}
          variant='link'
          className="absolute inset-y-0 right-0 pr-4 flex items-center text-primary"
        >
          <Cross1Icon
            className="h-4 w-4"
            aria-hidden="true"
          />
        </Button>
      )}
    </div>
  )
}

export default SearchField
