import * as React from "react"
import {
  hashKey,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query"
import type {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "@tanstack/react-query"
import { Observable } from "rxjs"
import { cleanupSubscription } from "./storage"
import { useObservableQueryFn } from "./useObservableQueryFn"

type TPageParam = string | number

export type UseInfiniteSubscriptionOptions<
  TSubscriptionFnData = unknown,
  TError = Error,
  TData = TSubscriptionFnData,
  TSubscriptionKey extends QueryKey = QueryKey,
> = Pick<
  UseInfiniteQueryOptions<
    TSubscriptionFnData,
    TError,
    TData,
    TSubscriptionFnData,
    TSubscriptionKey,
    TPageParam
  >,
  | "enabled"
  | "retry"
  | "retryDelay"
  | "refetchOnMount"
  | "placeholderData"
  | "select"
  | "getNextPageParam"
  | "getPreviousPageParam"
  | "initialPageParam"
> & {
  /**
   * This function will fire any time the subscription successfully fetches
   * new data or the cache is updated via setQueryData.
   */
  onData?: (data: TData) => void
  onError?: (error: TError) => void
}

export type UseInfiniteSubscriptionResult<
  TData = unknown,
  TError = unknown,
> = UseInfiniteQueryResult<TData, TError>

// eslint-disable-next-line @typescript-eslint/ban-types
const inOperator = <K extends string, T extends object>(
  k: K,
  o: T
): o is T & Record<K, unknown> => k in o

const isInfiniteData = (value: any): value is InfiniteData<unknown> =>
  value &&
  typeof value === "object" &&
  inOperator("pages", value) &&
  Array.isArray(value.pages) &&
  inOperator("pageParams", value) &&
  Array.isArray(value.pageParams)

export function useInfiniteSubscription<
  TSubscriptionFnData = unknown,
  TError = Error,
  TData = InfiniteData<TSubscriptionFnData, TPageParam>,
  TSubscriptionKey extends QueryKey = QueryKey,
>(props: {
  subscriptionKey: TSubscriptionKey
  subscriptionFn: (
    context: QueryFunctionContext<TSubscriptionKey, TPageParam>
  ) => Observable<TSubscriptionFnData>
  options: UseInfiniteSubscriptionOptions<
    TSubscriptionFnData,
    TError,
    TData,
    TSubscriptionKey
  >
}): UseInfiniteSubscriptionResult<TData, TError> {
  const { subscriptionKey, subscriptionFn, options } = props
  const hashedSubscriptionKey = hashKey(subscriptionKey)

  const queryClient = useQueryClient()
  const { queryFn, clearErrors } = useObservableQueryFn(
    subscriptionFn,
    (
      data,
      previousData,
      pageParam
    ): InfiniteData<TSubscriptionFnData, TPageParam> => {
      if (!isInfiniteData(previousData)) {
        return {
          pages: [data],
          pageParams: [pageParam!],
        }
      }

      const pageIndex = previousData.pageParams.findIndex(
        (cursor) => pageParam === cursor
      )

      return {
        pages: [
          ...(previousData.pages.slice(0, pageIndex) as TSubscriptionFnData[]),
          data,
          ...(previousData.pages.slice(pageIndex + 1) as TSubscriptionFnData[]),
        ],
        pageParams: previousData.pageParams as TPageParam[],
      }
    }
  )

  const queryResult = useInfiniteQuery({
    ...options,
    queryFn,
    queryKey: subscriptionKey,
    retry: false,
    staleTime: Infinity,
    refetchInterval: undefined,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  React.useEffect(() => {
    if (queryResult.isSuccess) {
      options?.onData?.(queryResult.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data])

  React.useEffect(() => {
    if (queryResult.error) {
      clearErrors()
      options?.onError?.(queryResult.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.error])

  React.useEffect(() => {
    return function cleanup() {
      const activeObserversCount = queryClient
        .getQueryCache()
        .find(subscriptionKey as any)
        ?.getObserversCount()

      if (activeObserversCount === 0) {
        cleanupSubscription(queryClient, hashedSubscriptionKey)
      }
    }
    // This is safe as `hashedSubscriptionKey` is derived from `subscriptionKey`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, hashedSubscriptionKey])

  return queryResult
}
