import React, { Component, ErrorInfo, ReactNode } from 'react'
import * as Sentry from "@sentry/react";

import ErrorPage from '~/pages/ErrorPage'
import { isSentryEnabled } from "../config/instrument"

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} />
    }

    return this.props.children
  }
}

const SentryErrorBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary
    showDialog
    fallback={ErrorPage}
  >
    {children}
  </Sentry.ErrorBoundary>
)

const ErrorBoundaryComp = isSentryEnabled()
  ? SentryErrorBoundary
  : ErrorBoundary

export default ErrorBoundaryComp
