export interface Language {
  name: string
  code: string
}

// Supported dictation languages
export const supportedDictationLanguages: Language[] = [
  { name: 'English', code: 'en' },
  { name: 'Swedish', code: 'sv' },
  { name: 'Polish', code: 'pl' },
]
