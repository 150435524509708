import {
  RecordingMessage,
  RecordingRPCResponse,
  RecordingSyncRequests,
  RecordingSyncResponses,
} from "./recordingSyncProtocol"
import { isoTimestamp } from "./timestamps"

export class RecordingSyncClientError extends Error {
  constructor(reason?: string) {
    super(reason)
  }
}

export class RecordingSyncClient {
  constructor() {}

  private async getServiceWorker(): Promise<ServiceWorker> {
    if (!("serviceWorker" in navigator)) {
      return Promise.reject(
        new RecordingSyncClientError(
          "Browser does not support service workers."
        )
      )
    }

    const allRegisteredWorkers =
      await navigator.serviceWorker.getRegistrations()

    if (allRegisteredWorkers.length == 0) {
      return Promise.reject(
        new RecordingSyncClientError(
          "Could not find any registered service workers"
        )
      )
    }

    const swReg = await navigator.serviceWorker.ready

    if (!swReg.active) {
      return Promise.reject(
        new RecordingSyncClientError("Could not find an active service worker.")
      )
    }

    return swReg.active
  }

  async isActive(): Promise<boolean> {
    try {
      await this.getServiceWorker()
      return true
    } catch (err: unknown) {
      return false
    }
  }

  async start(): Promise<void> {
    return this.send({ kind: "start" })
  }

  async stop(): Promise<void> {
    return this.send({ kind: "stop" })
  }

  async send(message: RecordingMessage): Promise<void> {
    // fire and forget a message
    const sw = await this.getServiceWorker()
    sw.postMessage(message)
  }

  async call<
    Req extends RecordingSyncRequests,
    Resp extends RecordingSyncResponses,
  >(request: Req): Promise<Resp> {
    const sw = await this.getServiceWorker()

    const rpcId = `${isoTimestamp()}-${Math.round(Math.random() * 10000)}`
    const responseKind = request.kind.replace("request", "response")
    const rpcRequest = {
      ...request,
      rpcId,
    }

    const rpcPromise: Promise<Resp> = new Promise((resolve, reject) => {
      navigator.serviceWorker.addEventListener(
        "message",
        function handler(ev: MessageEvent) {
          // response received so we don't need to listen anymore
          navigator.serviceWorker.removeEventListener("message", handler)

          const response = ev.data as RecordingRPCResponse

          // TODO: This is not sufficient when in a multi-threaded env
          // We also need to handle the case when more than one RPC request
          // can be in-flight and the responses are received in a different
          // order.

          // must match the id we sent out
          if (!("rpcId" in response && response.rpcId === rpcId)) {
            reject(
              new RecordingSyncClientError(
                `RPC response ID did not match request ${rpcRequest.rpcId} != ${response.rpcId}`
              )
            )
          }

          // must match the expected response kind
          if (!("kind" in response && response.kind === responseKind)) {
            reject(
              new RecordingSyncClientError(
                `RPC response kind did not match request ${responseKind} != ${response.rpcId}`
              )
            )
          }

          // Response does not conform to the protocol
          if (!("status" in response)) {
            reject(
              new RecordingSyncClientError(
                `RPC response does not have a status property as expected`
              )
            )
          }

          if (response.status === "error") {
            if (
              "error" in response &&
              response.error !== undefined &&
              "code" in response.error &&
              "message" in response.error
            ) {
              reject(
                new RecordingSyncClientError(
                  `RPC failed, ${response.error.code}, with message: ${response.error.message}`
                )
              )
            } else {
              reject(
                new RecordingSyncClientError(
                  `RPC failed, but error was not following the expected protocol: ${JSON.stringify(response.error)}`
                )
              )
            }
          }

          resolve(ev.data as Resp)
        }
      )
    })

    // send out the RPC request including the ID and wait for the response
    sw.postMessage(rpcRequest)

    return rpcPromise
  }
}
