import { useEffect, useReducer, useState } from "react"
import { FirebaseError } from "@firebase/util"
import { Link, useNavigate } from "react-router-dom"
import AccountField from "~/components/AccountField"
import EditPhoneNumberModal from "~/components/EditPhoneNumberModal"
import { LoginModal, LoginModalState } from "~/components/LoginModal"
import Pincode from "~/components/Pincode"
import { Button, toast } from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { useRecaptcha } from "~/hooks/useRecaptcha"
import { useUserProfile, useUserUpdateProfile } from "~/hooks/useUserProfile"
import {
  enrollUserInMultiFactor,
  getUserFriendlyAuthError,
  signin,
  verifyPhoneNumber,
} from "~/lib/auth"
import { PROMPTS } from "~/utils/prompts"

enum MFA_SETUP_STATE {
  PHONE_NUMBER = "PHONE_NUMBER",
  VERIFICATION = "VERIFICATION",
  REFRESH_LOGIN = "REFRESH_LOGIN",
  COMPLETED = "COMPLETED",
}

interface MFAData {
  state: MFA_SETUP_STATE
  email: string
  password: string
  phoneNumber: string
  verificationCodeId: string | null
}

enum MFAActionKind {
  SET_STATE = "SET_STATE",
  SET_EMAIL = "SET_EMAIL",
  SET_PASSWORD = "SET_PASSWORD",
  SET_VERIFICATION_CODE_ID = "SET_VERIFICATION_CODE_ID",
  SET_PHONE_NUMBER = "SET_PHONE_NUMBER",
}

type MFAAction =
  | { type: MFAActionKind.SET_STATE; payload: MFA_SETUP_STATE }
  | { type: MFAActionKind.SET_EMAIL; payload: string }
  | { type: MFAActionKind.SET_PASSWORD; payload: string }
  | { type: MFAActionKind.SET_VERIFICATION_CODE_ID; payload: string | null }
  | { type: MFAActionKind.SET_PHONE_NUMBER; payload: string }

function mfaReducer(mfaData: MFAData, action: MFAAction) {
  switch (action.type) {
    case MFAActionKind.SET_STATE:
      return {
        ...mfaData,
        state: action.payload,
      }
    case MFAActionKind.SET_EMAIL:
      return {
        ...mfaData,
        email: action.payload,
      }
    case MFAActionKind.SET_PASSWORD:
      return {
        ...mfaData,
        password: action.payload,
      }
    case MFAActionKind.SET_VERIFICATION_CODE_ID:
      return {
        ...mfaData,
        verificationCodeId: action.payload,
      }
    case MFAActionKind.SET_PHONE_NUMBER:
      return {
        ...mfaData,
        phoneNumber: action.payload,
      }
    default:
      throw new Error("Unknown action type")
  }
}

export default function SetupMFA() {
  const navigate = useNavigate()

  const recaptcha = useRecaptcha("setupmfa-recaptcha-container")

  const [enrollingUser, setEnrollingUser] = useState(false)
  const [userProfile, , isPending] = useUserProfile()

  const initialMFADataState: MFAData = {
    state: MFA_SETUP_STATE.PHONE_NUMBER,
    email: "",
    password: "",
    phoneNumber: "",
    verificationCodeId: "",
  }

  const [mfaData, dispatchMFAData] = useReducer(mfaReducer, initialMFADataState)

  const { currentUser } = useAuth()

  const handleAuthenticateUser = async (data: {
    email: string
    password: string
  }) => {
    try {
      const userCredential = await signin(data.email, data.password)
      if (userCredential.user?.uid === null) {
        throw new Error("User ID is null")
      }
      handleRestart()
    } catch (error) {
      toast.error("Authentication failed. Please try again.")
      console.log("Error authenticating user:", error)
    }
  }

  const enrollUser = async (code: string) => {
    if (enrollingUser) {
      toast.info("Already enrolling user")
      return
    }
    if (!mfaData.verificationCodeId) {
      toast.error("No verification code ID found")
      return
    }
    setEnrollingUser(true)

    try {
      await enrollUserInMultiFactor(
        currentUser!,
        mfaData.phoneNumber,
        mfaData.verificationCodeId,
        code
      )
      toast.success("Multi-factor authentication enabled")
      navigate("/account")
    } catch (error) {
      console.error("Error enrolling user:", error)

      if (error instanceof FirebaseError) {
        handleFirebaseError(error)
      } else {
        toast.error("Unexpected error occurred during enrollment")
      }
    } finally {
      setEnrollingUser(false)
    }
  }

  const handleFirebaseError = (error: FirebaseError) => {
    const message = getUserFriendlyAuthError(
      error,
      "Could not enroll in multi-factor authentication"
    )
    toast.error(message)
  }

  const handleRestart = () => {
    dispatchMFAData({
      type: MFAActionKind.SET_VERIFICATION_CODE_ID,
      payload: null,
    })
    dispatchMFAData({
      type: MFAActionKind.SET_STATE,
      payload: MFA_SETUP_STATE.PHONE_NUMBER,
    })
  }

  const handleSendVerificationCode = () => {
    if (!mfaData.phoneNumber || !recaptcha) {
      return
    }
    verifyPhoneNumber(currentUser!, mfaData.phoneNumber, recaptcha)
      .then((verificationId) => {
        if (!verificationId) {
          toast.error("Couldn't verify phone number")
        } else {
          dispatchMFAData({
            type: MFAActionKind.SET_VERIFICATION_CODE_ID,
            payload: verificationId,
          })
          dispatchMFAData({
            type: MFAActionKind.SET_STATE,
            payload: MFA_SETUP_STATE.VERIFICATION,
          })
        }
      })
      .catch((error) => {
        if ((error as FirebaseError)?.code === "auth/requires-recent-login") {
          dispatchMFAData({
            type: MFAActionKind.SET_STATE,
            payload: MFA_SETUP_STATE.REFRESH_LOGIN,
          })
        } else {
          if (error instanceof FirebaseError) {
            handleFirebaseError(error)
          } else {
            toast.error("Error sending verification code ")
          }
        }
      })
  }

  useEffect(() => {
    if (userProfile?.phoneNumber) {
      dispatchMFAData({
        type: MFAActionKind.SET_PHONE_NUMBER,
        payload: userProfile.phoneNumber,
      })
    }
    if (userProfile?.email) {
      dispatchMFAData({
        type: MFAActionKind.SET_EMAIL,
        payload: userProfile.email,
      })
    }
    return () => {}
  }, [userProfile, dispatchMFAData])

  const updateProfileMutation = useUserUpdateProfile({
    onSuccess: () => {
      toast.success("Phone number updated successfully")
    },
  })

  return (
    <div className="overflow-y-auto h-full bg-primary-cream-300">
      <h2
        className={`px-6 text-primary-black text-[32px] font-medium leading-normal tracking-tighter font-platypi`}
      >
        Setup multi-factor authentication
      </h2>

      <LoginModal
        open={mfaData.state === MFA_SETUP_STATE.REFRESH_LOGIN}
        state={LoginModalState.EMAIL_PASSWORD}
        title="You need to re-authenticate to continue"
        email={userProfile?.email ? userProfile?.email : ""}
        handleClose={handleRestart}
        handleLogin={handleAuthenticateUser}
        handleCode={async () => {}}
      />

      {isPending && (
        <div className="flex justify-center items-center h-full">
          Loading...
        </div>
      )}

      <div id="setupmfa-recaptcha-container" />

      <div className="my-6 p-4 pt-6 pb-10 flex flex-col gap-4 bg-white mx-auto w-100 md:w-[600px]">
        {mfaData.state === MFA_SETUP_STATE.PHONE_NUMBER && (
          <div className="flex flex-col justify-start mx-auto">
            <h3 className="flex items-start text-center my-2 text-primary-black text-[24px] font-bold tracking-tight leading-normal ">
              {PROMPTS.VERIFY_PHONE_NUMBER}
            </h3>

            <div>
              <AccountField
                fieldDescription="Phone number"
                fieldValue={
                  <span className="flex flex-row items-center gap-1.5">
                    {userProfile?.phoneNumber && (
                      <AccountField.FlagIcon code={userProfile?.phoneNumber} />
                    )}
                    <p className="text-lg">
                      {userProfile?.phoneNumber
                        ? userProfile?.phoneNumber
                        : "<Your phone number>"}
                    </p>
                  </span>
                }
              >
                <EditPhoneNumberModal
                  title={"Edit phone number"}
                  fieldName={"Phone number"}
                  fieldValue={userProfile?.phoneNumber || ""}
                  handleSubmit={async ({ fieldValue }) => {
                    await updateProfileMutation.mutateAsync({
                      phoneNumber: fieldValue,
                    })
                  }}
                >
                  <AccountField.EditButton />
                </EditPhoneNumberModal>
              </AccountField>
            </div>
            <hr />

            <div className="text-sm mt-3 text-fiord">
              {PROMPTS.ENTER_PHONE_NUMBER}
              <div>
                Verification codes will be sent to this number when you sign in
                to Joy Notes.
              </div>
            </div>

            <div className="mt-5 flex flex-row justify-around items-center gap-2 w-full">
              <div className="border-b border-black">
                <Link to="/account">Cancel</Link>
              </div>

              <Button onClick={handleSendVerificationCode}>
                Send Verification Code
              </Button>
            </div>
          </div>
        )}

        {mfaData.state === MFA_SETUP_STATE.VERIFICATION && (
          <div className="flex flex-col justify-start items-start ml-24">
            <h2 className="text-center my-2 font-bold leading-10 text-xl">
              {PROMPTS.ENTER_VERIFICATION_CODE}
            </h2>

            <div className="text-md">
              We sent your verification code to: {mfaData.phoneNumber}
            </div>

            <div className="my-3 flex flex-row justify-start items-start w-full">
              <Pincode
                submitText={enrollingUser ? "Submitting..." : "Submit"}
                handleSubmit={async (code: string) => {
                  await enrollUser(code)
                }}
              />
            </div>

            <div className="flex flex-col space-y-2 items-start justify-start">
              <Button
                variant="ghost"
                className="hover:bg-sky-200 hover:bg-opacity-40 py-1 px-4 rounded-md text-sm"
                onClick={handleSendVerificationCode}
              >
                Resend code
              </Button>
              <Button
                variant="ghost"
                className="hover:bg-sky-200 hover:bg-opacity-40 py-1 px-4 rounded-md text-sm"
                onClick={handleRestart}
              >
                Verify with a different number
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
