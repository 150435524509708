import parseSemver from './semver'

export const parseTranscript = (transcriptJsonString: string) => {
  if (transcriptJsonString === undefined || transcriptJsonString === '') {
    return 'No transcript available'
  }
  const jsonObject = JSON.parse(transcriptJsonString)
  const note =
    parseSemver(jsonObject?.version)?.major === 1
      ? parseTranscriptVersion_1(jsonObject)
      : parseDeepgramTranscript(jsonObject)
  return note
}

function ensureUpperCaseAfterNewLine(text: string): string {
  return text.replace(/(\n\s*)([a-z])/g, (_match, p1: string, p2: string) => {
    return p1 + p2.toUpperCase()
  })
}

export const parseTranscriptVersion_1 = (jsonObject: unknown): string => {
  interface Transcript {
    segments:
      | {
          text: string
        }[]
      | null
  }

  const transcriptObject = jsonObject as Transcript

  if (!transcriptObject || !transcriptObject.segments || !Array.isArray(transcriptObject.segments)) {
    return 'No transcript available'
  }

  interface Segment {
    text: string
  }

  // Go through segments and search for new paragraph command
  const newParagraphCommand = [
    'nytt stycke', // Swedish
    'ny paragraf',
    'new section', // English
    'new paragraph',
    'nowy akapit', // Polish
    'nowy paragraf',
  ]

  // Create a regular expression pattern for the new paragraph command
  // Example: \s*[.,\s]*(nytt stycke|ny paragraf|new paragraph|new section)[.,\s]*
  const newParagraphPattern = new RegExp(`\\s*[.,\\s]*(${newParagraphCommand.join('|')})[.,\\s]*`, 'gi')

  // Check if new paragraph command exist in any segment
  const newParagraphCommandUsed = transcriptObject.segments.some(
    (segment: Segment) => segment && newParagraphPattern.test(segment.text),
  )

  let note = ''

  try {
    if (newParagraphCommandUsed) {
      // Combine all segments into one string
      const combinedText = transcriptObject.segments.map((segment: Segment) => segment.text).join(' ')

      // Replace the new paragraph command with a period and two new lines
      note = combinedText.replace(newParagraphPattern, '.\n\n')
    } else {
      transcriptObject.segments.forEach((segment: Segment) => {
        if (segment.text) {
          const segmentText = segment.text.trim()
          if (segmentText.length === 0) {
            return
          }

          // Add segment
          note += segmentText

          // Add a space if the segment does not end with a punctuation
          const lastChar = segmentText[segmentText.length - 1]
          if (!/[.,:;!?]$/.test(lastChar)) {
            note += ' '
          }

          // Add a new line if the segment ends with a terminal punctuation
          if (/[.:!?]$/.test(lastChar)) {
            note = note.trimEnd() + '\n\n'
          }
        }
      })
    }

    // Ensure that the first letter after a new line is capitalized
    note = ensureUpperCaseAfterNewLine(note)

    // trim trailing new lines
    note = note.trimEnd()
  } catch (error) {
    console.error('Error processing transcript:', error)
    return 'No transcript available'
  }

  return note
}

const autofixCommonTranscriptionErrors = (text: string) => {
  const commonErrors = [
    { incorrect: / I /g, correct: ' i ' },
    { incorrect: /KVA/g, correct: 'KVÅ' },
    { incorrect: /terafin/g, correct: 'terapin' },
    { incorrect: /semesterbehollet/g, correct: 'semesteruppehållet' },
    { incorrect: /vända vaccin/gi, correct: 'Venlafaxin' },
    { incorrect: /vänd la vaccin/gi, correct: 'Venlafaxin' },
    { incorrect: /vendla vaccin/gi, correct: 'Venlafaxin' },
    { incorrect: /vendla-vaccin/gi, correct: 'Venlafaxin' },
    { incorrect: /vennla-vaccin/gi, correct: 'Venlafaxin' },
    { incorrect: /prämalex/g, correct: 'Premalex' },
    { incorrect: /anafralin/g, correct: 'Anafralin' },
    { incorrect: /sarotén/gi, correct: 'Saroten' },
    { incorrect: /citalopram/g, correct: 'Citalopram' },
    { incorrect: /Promet/g, correct: 'programmet' },
  ]

  let newText = text
  for (let i = 0; i < commonErrors.length; i++) {
    newText = newText.replace(commonErrors[i].incorrect, commonErrors[i].correct)
  }

  return newText
}

export const parseDeepgramTranscript = (jsonObject: unknown): string => {
  interface Sentence {
    text: string
  }

  interface Paragraph {
    sentences: Sentence[]
  }

  interface DeepgramTranscript {
    results: {
      channels: {
        alternatives: {
          paragraphs: {
            paragraphs: Paragraph[]
          }
        }[]
      }[]
    }
  }

  const transcriptObject = jsonObject as DeepgramTranscript

  if (!transcriptObject || !transcriptObject.results || !Array.isArray(transcriptObject.results.channels)) {
    return 'No transcript available'
  }

  let note = ''

  try {
    const paragraphs = transcriptObject.results.channels[0]?.alternatives?.[0]?.paragraphs?.paragraphs

    if (!paragraphs || !Array.isArray(paragraphs)) {
      throw new Error('Paragraphs is not an array or is undefined.')
    }

    paragraphs.forEach((paragraph: Paragraph) => {
      if (paragraph && Array.isArray(paragraph.sentences)) {
        paragraph.sentences.forEach((sentence: Sentence) => {
          if (sentence && sentence.text) {
            note += sentence.text + ' '
          }
        })
        note += '\n\n'
      }
    })

    // trim trailing new lines
    note = note.trimEnd()
  } catch (error) {
    console.error('Error processing transcript:', error)
    return 'No transcript available'
  }

  return autofixCommonTranscriptionErrors(note)
}
