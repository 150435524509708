import React, { useEffect, useRef, useState } from "react"
import {
  BAR_GAP,
  BAR_HEIGHT_MAX,
  BAR_HEIGHT_MIN,
  BAR_WIDTH,
  canvasHeight,
  canvasWidth,
} from "~/utils/const-strings"
import { getColor } from "~/utils/getColors"
import {
  calculateBarHeight,
  reduceArrayToAverages,
} from "~/utils/helperFunctions"

interface AudioVolumeVisualizerProps {
  frequencyData: number[]
  barColor: string
}

function AudioVolumeVisualizer({
  frequencyData,
  barColor = getColor("transparent_gray"),
}: AudioVolumeVisualizerProps) {
  const [useFallback, setUseFallback] = useState<boolean>(false)
  const [reducedFrequencyData, setReducedFrequencyData] = useState<number[]>([])
  const canvasRef = useRef<HTMLCanvasElement>(null)

  // Function to draw a series of bars
  const drawBars = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    data: number[],
    color: string
  ) => {
    ctx.fillStyle = color
    ctx.beginPath()
    for (let i = 0; i < data.length; i++) {
      const x = i * (BAR_WIDTH + BAR_GAP)
      const height = (data[i] * BAR_HEIGHT_MAX) / 100
      const barHeight = Math.min(
        Math.max(height, BAR_HEIGHT_MIN),
        BAR_HEIGHT_MAX
      )
      const y = canvas.height / 2 - barHeight / 2 // Center the bar on canvas
      ctx.rect(x, y, BAR_WIDTH, barHeight)
    }
    ctx.fill()
  }

  useEffect(() => {
    try {
      const reducedData = reduceArrayToAverages(frequencyData)
      setReducedFrequencyData(reducedData)

      // If the canvas ref is not set, return
      if (!canvasRef.current) {
        console.log("Canvas ref not set")
        return
      }

      // Get the canvas and context
      const canvas = canvasRef.current!
      const ctx = canvas.getContext("2d")!

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw histogram bars
      drawBars(ctx, canvas, reducedData, barColor)
    } catch (error) {
      console.error("Error drawing audio volume visualizer:", error)
      setUseFallback(true)
    }
  }, [frequencyData])

  return useFallback ? (
    <div
      className="flex items-center"
      style={{ minHeight: canvasHeight }}
    >
      {[...Array(reducedFrequencyData.length)].map((_, i) => (
        <div
          key={i}
          style={{
            width: `${BAR_WIDTH}px`,
            height: calculateBarHeight(reducedFrequencyData[i]),
            backgroundColor: barColor,
            display: "inline-block",
            marginRight: `${BAR_GAP}px`,
          }}
        />
      ))}
    </div>
  ) : (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
    />
  )
}

export default AudioVolumeVisualizer
