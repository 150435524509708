import { useCallback, useContext, useMemo, useTransition } from "react"
import { useMutation } from "@tanstack/react-query"
import equal from "fast-deep-equal"
import { doc, updateDoc } from "firebase/firestore"
import { useAuth } from "~/context/AuthContext"
import { UserContext } from "~/context/UserContext"
import { db } from "~/services/firebase"
import { UserProfileType } from "~/types/userTypes"
import type { MutationOptions } from "../lib/react-query"

const isEqualPartial = (
  partial: Partial<UserProfileType>,
  full: UserProfileType | undefined
): boolean => {
  if (!full) return false

  return Object.keys(partial).every((key) => {
    const k = key as keyof UserProfileType
    return JSON.stringify(partial[k]) === JSON.stringify(full[k])
  })
}

export function useUserProfile(): [
  UserProfileType | null,
  (updates: Partial<UserProfileType>) => void,
  boolean,
] {
  const userContext = useContext(UserContext)
  const user = userContext?.user
  const { currentUser } = useAuth()

  const [isPending, startTransition] = useTransition()

  /** @deprecated */
  const updateUserProfile = useCallback(
    (updates: Partial<UserProfileType>) => {
      if (!isEqualPartial(updates, user?.userProfile)) {
        if (!currentUser?.uid) {
          return
        }
        startTransition(() => {
          const userRef = doc(db, "users", currentUser?.uid)
          void updateDoc(userRef, {
            userProfile: { ...user?.userProfile, ...updates },
          })
        })
      }
    },
    [currentUser?.uid, user?.userProfile]
  )

  return useMemo(
    () => [user?.userProfile || null, updateUserProfile, isPending],
    [user?.userProfile, updateUserProfile, isPending]
  )
}

type UpdateProfileVars = Partial<UserProfileType>

export function useUserUpdateProfile({
  ...options
}: MutationOptions<UpdateProfileVars> = {}) {
  const userContext = useContext(UserContext)
  const user = userContext?.user
  const { currentUser } = useAuth()

  return useMutation({
    ...options,
    mutationFn: async (params: UpdateProfileVars) => {
      // We use onSnapshot on UserContext, so no need to trigger invalidation
      if (!equal(params, user?.userProfile) && currentUser?.uid) {
        const userRef = doc(db, "users", currentUser?.uid)

        await updateDoc(userRef, {
          userProfile: { ...user?.userProfile, ...params },
        })
      }
    },
  })
}
