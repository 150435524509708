import * as React from "react"
import { cn } from "./utils"

interface DataListRootProps extends React.ComponentPropsWithoutRef<"dl"> {}

const DataListRoot = React.forwardRef<HTMLDListElement, DataListRootProps>(
  (props, forwardedRef) => {
    const { className, ...dataListProps } = props

    return (
      <dl
        {...dataListProps}
        ref={forwardedRef}
        className={cn("grid grid-cols-[auto_1fr] text-left gap-2.5", className)}
      />
    )
  }
)
DataListRoot.displayName = "DataList.Root"

interface DataListItemProps extends React.ComponentPropsWithoutRef<"div"> {}

const DataListItem = React.forwardRef<HTMLDivElement, DataListItemProps>(
  (props, forwardedRef) => {
    const { className, ...itemProps } = props

    return (
      <div
        {...itemProps}
        ref={forwardedRef}
        className={cn(
          "grid grid-cols-subgrid col-[span_2] align-baseline text-sm",
          className
        )}
      />
    )
  }
)
DataListItem.displayName = "DataList.Item"

interface DataListLabelProps extends React.ComponentPropsWithoutRef<"dt"> {}

const DataListLabel = React.forwardRef<
  React.ElementRef<"dt">,
  DataListLabelProps
>((props, forwardedRef) => {
  const { className, ...labelProps } = props

  return (
    <dt
      {...labelProps}
      ref={forwardedRef}
      className={cn("flex text-muted-foreground min-w-40", className)}
    />
  )
})
DataListLabel.displayName = "DataList.Label"

interface DataListValueProps extends React.ComponentPropsWithoutRef<"dd"> {}

const DataListValue = React.forwardRef<
  React.ElementRef<"dd">,
  DataListValueProps
>(({ children, className, ...props }, forwardedRef) => (
  <dd
    {...props}
    ref={forwardedRef}
    className={cn(className, "flex min-w-0 font-medium")}
  >
    {children}
  </dd>
))
DataListValue.displayName = "DataList.Value"

export { DataListRoot, DataListItem, DataListLabel, DataListValue }
