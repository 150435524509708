import * as React from "react"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import { FilePenLine } from "lucide-react"
import proofreadIcon from "~/assets/icons/proofread-icon.png"
import soapBtnIcon from "~/assets/icons/soap-btn-icon.png"
import { cn } from "~/components/ui/utils"
import { TransformType } from "./types"

interface NoteTransformButtonsProps {
  selectedButton: TransformType
  setSelectedButton: React.Dispatch<React.SetStateAction<TransformType>>
}

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>
>(({ children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root
    ref={ref}
    className={
      "flex justify-center bg-medium-grey-600 w-fit rounded-2xl items-center x425:min-w-80"
    }
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Root>
))
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>
>(({ children, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        "group flex flex-col justify-center cursor-pointer items-center text-base x425:text-lg font-normal font-archivo",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-normal-black-400 focus-visible:ring-offset-2",
        "text-normal-black-400 h-9 w-24 x425:w-28 rounded",
        "data-[state=on]:bg-normal-black-400 data-[state=on]:text-white data-[state=on]:rounded-xl data-[state=on]:py-[0.875rem] data-[state=on]:px-5 data-[state=on]:w-24 data-[state=on]:h-auto data-[state=on]:x425:w-28"
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  )
})
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

const VerticalDivider = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 256 256"
    className="mx-[-9px]"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M134,24V232a6,6,0,0,1-12,0V24a6,6,0,0,1,12,0Z"></path>
  </svg>
)

export default function NoteTransformButtons({
  selectedButton,
  setSelectedButton,
}: NoteTransformButtonsProps) {
  return (
    <div className="flex justify-center h-14 mb-8">
      <ToggleGroup
        type="single"
        orientation="horizontal"
        value={selectedButton}
        onValueChange={(val) => {
          if (val) setSelectedButton(val as TransformType)
        }}
      >
        <ToggleGroupItem value={TransformType.Default}>
          <img
            src={proofreadIcon}
            alt="Proofread Icon"
            className="w-6 h-6 mb-4 group-data-[state=off]:hidden"
          />
          <span>Proofread</span>
        </ToggleGroupItem>

        <VerticalDivider />

        <ToggleGroupItem value={TransformType.SOAP}>
          <img
            src={soapBtnIcon}
            alt="SOAP Icon"
            className="w-6 h-6 mb-4 group-data-[state=off]:hidden"
          />
          <span>SOAP</span>
        </ToggleGroupItem>

        <VerticalDivider />

        <ToggleGroupItem value={TransformType.MyOwn}>
          <FilePenLine className="w-6 h-6 mb-4 group-data-[state=off]:hidden" />
          <span>My own</span>
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  )
}
