import { FirebaseError } from "@firebase/util"
import * as firebaseAuth from "firebase/auth"
import { auth } from "~/services/firebase"

export type AuthUser = firebaseAuth.User
export type VerificationMFA = {
  verificationId: string
  resolver: firebaseAuth.MultiFactorResolver
}

export function signup(email: string, password: string) {
  return firebaseAuth.createUserWithEmailAndPassword(auth, email, password)
}

export function resendVerificationEmail(user: AuthUser) {
  return firebaseAuth.sendEmailVerification(user)
}

export function sendVerificationEmail(user: AuthUser) {
  return firebaseAuth.sendEmailVerification(user)
}

export function linkAnonymousAccount(
  user: AuthUser,
  email: string,
  password: string
): Promise<firebaseAuth.UserCredential> {
  const credential = firebaseAuth.EmailAuthProvider.credential(email, password)

  return firebaseAuth.linkWithCredential(user, credential)
}

export function applyAuthActionCode(code: string) {
  return firebaseAuth.applyActionCode(auth, code)
}

export function checkAuthActionCode(code: string) {
  return firebaseAuth.checkActionCode(auth, code)
}

export function confirmPasswordResetWithActionCode(
  code: string,
  newPassword: string
) {
  return firebaseAuth.confirmPasswordReset(auth, code, newPassword)
}

export function signin(email: string, password: string) {
  return firebaseAuth.signInWithEmailAndPassword(auth, email, password)
}

export function signout() {
  return firebaseAuth.signOut(auth)
}

export function resetPassword(email: string) {
  return firebaseAuth.sendPasswordResetEmail(auth, email)
}

export function changeEmail(user: AuthUser, email: string) {
  return firebaseAuth.updateEmail(user, email)
}

export function changePassword(user: AuthUser, password: string) {
  return firebaseAuth.updatePassword(user, password)
}

export function reauthenticate(user: AuthUser, password: string) {
  if (!user.email) {
    return Promise.reject(new Error("No email associated with user"))
  }

  const credential = firebaseAuth.EmailAuthProvider.credential(
    user.email,
    password
  )

  return firebaseAuth.reauthenticateWithCredential(user, credential)
}

export function deleteAccount(user: AuthUser) {
  return firebaseAuth.deleteUser(user)
}

export function verifyIfUserIsEnrolledInMultiFactor(user: AuthUser) {
  return firebaseAuth.multiFactor(user).enrolledFactors.length > 0
}

export async function verifyPhoneNumber(
  user: AuthUser,
  phoneNumber: string,
  applicationVerifier: firebaseAuth.ApplicationVerifier
) {
  const session = await firebaseAuth.multiFactor(user).getSession()
  const phoneAuthProvider = new firebaseAuth.PhoneAuthProvider(auth)

  const phoneInfoOptions = {
    phoneNumber,
    session,
  }

  return await phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    applicationVerifier
  )
}

export async function enrollUserInMultiFactor(
  user: AuthUser,
  phoneNumber: string,
  verificationCodeId: string,
  verificationCode: string
) {
  const phoneAuthCredential = firebaseAuth.PhoneAuthProvider.credential(
    verificationCodeId,
    verificationCode
  )

  const multiFactorAssertion =
    firebaseAuth.PhoneMultiFactorGenerator.assertion(phoneAuthCredential)

  return await firebaseAuth
    .multiFactor(user)
    .enroll(multiFactorAssertion, "My personal phone number")
}

export async function verifyUserEnrolled(
  verificationMFA: VerificationMFA,
  verificationCode: string
) {
  const { verificationId, resolver } = verificationMFA
  const credentials = firebaseAuth.PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  )
  const multiFactorAssertion =
    firebaseAuth.PhoneMultiFactorGenerator.assertion(credentials)
  return await resolver.resolveSignIn(multiFactorAssertion)
}

export async function resendVerificationCode(
  recaptchaVerifier: firebaseAuth.ApplicationVerifier,
  resolver: firebaseAuth.MultiFactorResolver
) {
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  }

  // Send SMS verification code.
  const phoneAuthProvider = new firebaseAuth.PhoneAuthProvider(auth)
  await phoneAuthProvider
    .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
    .then(function (verificationId) {
      return verificationId
      // verificationId will be needed for sign-in completion.
    })

  return null
}

export async function getMFAResolverAndVerificationId(
  multifactorError: firebaseAuth.MultiFactorError,
  recaptchaVerifier: firebaseAuth.ApplicationVerifier,
  selectedIndex: number
) {
  const resolver = firebaseAuth.getMultiFactorResolver(auth, multifactorError)

  if (
    resolver.hints[selectedIndex].factorId ===
    firebaseAuth.PhoneMultiFactorGenerator.FACTOR_ID
  ) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    }

    // Send SMS verification code.
    const phoneAuthProvider = new firebaseAuth.PhoneAuthProvider(auth)
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    )
    return { verificationId, resolver }
  } else if (
    resolver.hints[selectedIndex].factorId ===
    firebaseAuth.TotpMultiFactorGenerator.FACTOR_ID
  ) {
    console.log("Error: TotpMultiFactorGenerator used")
  } else {
    console.log("Error: Unsupported second factor.")
  }

  return false
}

export async function unenrollUserInPhoneMultiFactor(user: AuthUser) {
  const multiFactorUser = firebaseAuth.multiFactor(user)

  const options = multiFactorUser.enrolledFactors
  const phoneOption = options.find(
    (option) =>
      option.factorId === firebaseAuth.PhoneMultiFactorGenerator.FACTOR_ID
  )

  if (phoneOption === undefined) {
    return false
  }

  return multiFactorUser.unenroll(phoneOption)
}

export const getUserFriendlyAuthError = (
  error: FirebaseError,
  defaultMessage: string
) => {
  const errorMessages: Record<string, string> = {
    "auth/second-factor-already-in-use":
      "Multi-factor authentication already enabled.",
    "auth/invalid-verification-code": "Invalid code used. Request a new code.",
    "auth/code-expired": "Code expired. Request a new code.",
    "auth/too-many-requests":
      "You've made too many requests. Please wait a few minutes and try again.",
    "auth/invalid-login-credentials": "Invalid email address or password",
    "auth/network-request-failed":
      "Oops! It looks like there's a problem with your internet connection. Please check your connection and try again.",
    "auth/email-already-in-use":
      "This email is already registered. Please login instead.",
  }

  const message = errorMessages[error.code] || defaultMessage
  return message
}
