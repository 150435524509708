import { Query } from "@firebase/firestore"
import {
  onSnapshot,
  type DocumentReference,
  type SnapshotListenOptions,
} from "firebase/firestore"
import { Observable } from "rxjs"

export function fromRef(
  ref: Query | DocumentReference,
  options?: SnapshotListenOptions
) {
  return new Observable((subscriber) => {
    const unsubscribe = onSnapshot(ref as any, options ?? {}, {
      next: subscriber.next.bind(subscriber),
      error: subscriber.error.bind(subscriber),
      complete: subscriber.complete.bind(subscriber),
    })

    return { unsubscribe }
  })
}
