import React from 'react'
import { Toaster as Sonner, toast } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      richColors
      className='toaster group'
      position='top-center'
      {...props}
    />
  )
}

export { Toaster, toast }
