import { useEffect, useState } from 'react'

const TIME_UNTIL_NO_SOUND_WARNING = 5000

// This hook is used to detect if the user is speaking into the microphone.
const useSoundDetection = (
  frequencyData: number[], // Array of frequency data
  time: number, // time since recoding started in ms
  notifySoundDetected: (message: string) => void, // Callback for sound detected notification
  notifyNoSoundDetected: (message: string) => void, // Callback for no sound notification
) => {
  const [soundDetected, setSoundDetected] = useState(false)
  const [userInformedAboutNoSound, setUserInformedAboutNoSound] = useState(false)

  useEffect(() => {
    if (!soundDetected && time > 0) {
      const totalFrequency = frequencyData.reduce((acc, curr) => acc + curr, 0)

      if (totalFrequency > 0) {
        setSoundDetected(true)
        userInformedAboutNoSound && notifySoundDetected('Sound detected. You seem good to go!')
      } else if (time > TIME_UNTIL_NO_SOUND_WARNING && !userInformedAboutNoSound) {
        setUserInformedAboutNoSound(true)
        notifyNoSoundDetected('No sound detected. Please check your microphone.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequencyData, time, soundDetected, userInformedAboutNoSound])

  return { soundDetected, setSoundDetected }
}

export default useSoundDetection
