import React from "react"
import { AriaSearchFieldProps } from "@react-types/searchfield"
import { SearchIcon, XIcon } from "lucide-react"
import { useButton, useFocusRing, useSearchField } from "react-aria"
import { useSearchFieldState } from "react-stately"
import { buttonVariants } from "./button"
import { Input } from "./input"
import { useMergedRefs } from "./internal"
import { cn } from "./utils"

const ClearButton = ({ ...props }) => {
  const ref = React.useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <button
      ref={ref}
      {...buttonProps}
      className={cn(
        buttonVariants({
          size: "icon",
          variant: "ghost",
          className:
            "absolute right-1 top-1/2 -translate-y-1/2 w-6 h-6 text-muted-foreground",
        }),
        props.className
      )}
    >
      <XIcon
        aria-hidden
        className="w-4 h-4"
      />
    </button>
  )
}

export type SearchInputProps = Omit<
  AriaSearchFieldProps,
  "label" | "description" | "errorMessage"
> & {
  className?: string
}

const SearchInput = React.forwardRef<
  React.ElementRef<"input">,
  SearchInputProps
>(({ ...props }, ref) => {
  const innerRef = React.useRef<HTMLInputElement>(null)
  const inputRef = useMergedRefs([ref, innerRef])

  const state = useSearchFieldState(props)
  const { inputProps, clearButtonProps } = useSearchField(
    {
      ...props,
      "aria-label": props["aria-label"] || "Search",
    },
    state,
    innerRef
  )

  const { isFocused, isFocusVisible, focusProps } = useFocusRing({
    within: true,
  })

  return (
    <div
      {...focusProps}
      className="group relative flex"
      data-empty={state.value === "" || undefined}
      data-focus-within={isFocused || undefined}
      data-focus-visible={isFocusVisible || undefined}
    >
      <button
        type="button"
        className={cn(
          buttonVariants({
            size: "icon",
            variant: "ghost",
          }),
          "absolute left-1 top-1/2 -translate-y-1/2 w-6 h-6 bg-transparent hover:bg-transparent text-muted-foreground"
        )}
      >
        <SearchIcon
          aria-hidden
          className="w-4 h-4"
        />
      </button>

      <Input
        ref={inputRef}
        {...inputProps}
        className={cn(
          "[&::-webkit-search-cancel-button]:hidden w-full px-8",
          props.className
        )}
      />

      <ClearButton
        {...clearButtonProps}
        className={state.value === "" ? "invisible" : ""}
      />
    </div>
  )
})
SearchInput.displayName = "SearchInput"

export { SearchInput }
