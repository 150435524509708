import { theme } from '../.././tailwind.config'

type Colors = typeof theme.extend.colors

export const getColor = (colorKey: keyof Colors, nestedKey?: number): string => {
  const color = theme.extend.colors[colorKey]

  if (typeof color === 'object' && nestedKey !== undefined) {
    return (color as { [key: number]: string })[nestedKey]
  }

  return color as string
}
