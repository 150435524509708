import { isValidPhoneNumber, type CountryCode } from "libphonenumber-js"

export function isPhoneValid(
  phone: string,
  defaultCountry: string | undefined = "GB"
) {
  try {
    return isValidPhoneNumber(phone, defaultCountry as CountryCode)
  } catch (error) {
    return false
  }
}
