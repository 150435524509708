import { useState } from "react"
import ThumbsdownFilledIcon from "~/assets/icons/hand-thumbsdown-filled.svg?react"
import ThumbsdownIcon from "~/assets/icons/hand-thumbsdown.svg?react"
import ThumbsupFilledIcon from "~/assets/icons/hand-thumbsup-filled.svg?react"
import ThumbsupIcon from "~/assets/icons/hand-thumbsup.svg?react"
import { Button } from "~/components/ui"

export const FeedbackButtons = ({
  onFeedback,
  feedback,
}: {
  onFeedback: (type: string) => void
  feedback: string
}) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const handleLikeClick = () => {
    setIsAnimating(true)
    onFeedback("like")
  }

  const handleAnimationEnd = () => setIsAnimating(false)

  return (
    <div className="flex justify-center items-center gap-1">
      <Button
        onClick={handleLikeClick}
        size="icon"
        variant="ghost"
        className={`${isAnimating ? "motion-safe:animate-tada" : ""}`}
        disabled={feedback !== ""}
        onAnimationEnd={handleAnimationEnd}
      >
        {feedback === "like" ? <ThumbsupFilledIcon /> : <ThumbsupIcon />}
      </Button>
      <Button
        onClick={() => onFeedback("dislike")}
        size="icon"
        variant="ghost"
        disabled={feedback !== ""}
      >
        {feedback === "dislike" ? <ThumbsdownFilledIcon /> : <ThumbsdownIcon />}
      </Button>
    </div>
  )
}
