import * as React from 'react'
import { Outlet } from 'react-router-dom'

export default function AuthPageLayout() {
  return (
    <>
      <div className="auth-layout">
        <div className="auth-layout-form">
          <Outlet />

          <footer className="py-3">
            <p className="text-xs text-center">
              © Joy {new Date().getFullYear()}
            </p>
          </footer>
        </div>
      </div>
      <div className="fixed inset-0 h-full delay-150 bg-cover bg-center bg-no-repeat bg-notes-landing [left:clamp(0px,(100vw-var(--Collapsed-breakpoint))*999,100vw-var(--Cover-width))]" />
    </>
  )
}
