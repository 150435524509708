import * as React from "react"
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react"
import {
  useButton,
  useNumberField,
  type AriaNumberFieldProps,
} from "react-aria"
import { useNumberFieldState } from "react-stately"
import { Input } from "./input"
import { useMergedRefs } from "./internal"
import { cn } from "./utils"

export interface NumberInputProps extends AriaNumberFieldProps {
  className?: string
}

const Stepper = ({ ...props }) => {
  const ref = React.useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <button
      ref={ref}
      {...buttonProps}
      className={cn(
        "flex items-center justify-center w-6 h-3 select-none leading-none hover:bg-accent disabled:opacity-40 disabled:cursor-not-allowed",
        props?.className
      )}
    >
      {props.children}
    </button>
  )
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ className, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const targetRef = useMergedRefs([ref, inputRef])

    const state = useNumberFieldState({ ...props, locale: "en-US" })
    const {
      groupProps,
      inputProps,
      incrementButtonProps,
      decrementButtonProps,
    } = useNumberField(
      {
        ...props,
        "aria-label": props["aria-label"] ?? props?.id ?? "",
      },
      state,
      inputRef
    )

    return (
      <div
        {...groupProps}
        className="relative group"
      >
        <Input
          ref={targetRef}
          {...inputProps}
          className={className}
        />

        <div className="hidden group-hover:flex absolute right-1.5 top-0 z-10 m-px h-[calc(100%-2px)] w-6 flex-col justify-center items-center">
          <Stepper
            type="button"
            {...incrementButtonProps}
          >
            <ChevronUpIcon size={14} />
          </Stepper>

          <Stepper
            type="button"
            {...decrementButtonProps}
          >
            <ChevronDownIcon size={14} />
          </Stepper>
        </div>
      </div>
    )
  }
)
NumberInput.displayName = "NumberInput"

export { NumberInput }
