import * as React from "react"
import {
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  type MDXEditorMethods,
  type MDXEditorProps,
} from "@mdxeditor/editor"
import { useKeyPress } from "ahooks"
import { cn } from "./utils"
import "@mdxeditor/editor/style.css"

type RichEditorProps = Omit<
  MDXEditorProps,
  "theme" | "plugins" | "markdown"
> & {
  value: string
  isInvalid?: boolean
  onRequestSave?: () => void
}

const plugins = [
  // Basic
  headingsPlugin(),
  listsPlugin(),
  quotePlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
  // Advanced
  linkPlugin(),
  tablePlugin(),
] as const

const RichEditor = React.forwardRef<MDXEditorMethods, RichEditorProps>(
  ({ isInvalid, onRequestSave, value, ...props }, ref) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null)

    // Request save on Ctrl+S or Cmd+S
    useKeyPress(
      ["ctrl.s", "meta.s"],
      (e) => {
        e.preventDefault()
        onRequestSave?.()
      },
      { target: wrapperRef }
    )

    return (
      <div ref={wrapperRef}>
        <MDXEditor
          ref={ref}
          {...props}
          markdown={value ?? ""}
          plugins={[...plugins]}
          contentEditableClassName="prose max-w-full leading-[1.25]"
          className={cn(
            "border-0 border-transparent hover:border-border rounded p-0",
            isInvalid && "border-destructive"
          )}
        />
      </div>
    )
  }
)
RichEditor.displayName = "RichEditor"

export { RichEditor, type RichEditorProps }
