import * as React from "react"
import type {
  CollectionReference,
  FirestoreDataConverter,
} from "@firebase/firestore"
import type { FirebaseError } from "@firebase/util"
import {
  useMutation,
  useQuery,
  useQueryClient,
  type InfiniteData,
} from "@tanstack/react-query"
import {
  addDoc,
  collection,
  deleteField,
  doc,
  FirestoreError,
  getDoc,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
  writeBatch,
  type SnapshotOptions,
} from "firebase/firestore"
import { collectionData, fromRef } from "rxfire/firestore"
import { map, Observable } from "rxjs"
import { useAnalytics } from "use-analytics"
import { useSavedCallback } from "~/components/ui/internal"
import { useAuth } from "~/context/AuthContext"
import {
  useInfiniteSubscription,
  type UseInfiniteSubscriptionOptions,
} from "~/hooks/firestore/useInfiniteSubscription"
import { useSubscription } from "~/hooks/firestore/useSubscription"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import {
  NoteStatus,
  Transform,
  TransformType,
  type Note,
} from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { PAGE_SIZE } from "~/utils/const-strings"
import { getNoteData, getTranscribedNote } from "~/utils/noteUtils"
import { parseTranscript } from "~/utils/transcriptParsers"
import { useQueue } from "./useQueue"

/* Queries & Subscription */
type GetNoteReturn = {
  data: Note[]
  nextCursor: number | null
}

const noteConverter: FirestoreDataConverter<Note> = {
  toFirestore: (note: Note) => note,
  fromFirestore: (snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) =>
    ({ ...snapshot.data(options), id: snapshot.id }) as Note,
}

export function getNotes$(
  userId: string,
  cursor: { after: number } | { before: number }
): Observable<GetNoteReturn> {
  const isLiveNotes = "after" in cursor

  const constraints: QueryConstraint[] = isLiveNotes
    ? [orderBy("createdAt", "desc"), limit(PAGE_SIZE + 1)]
    : [
        orderBy("createdAt", "desc"),
        startAfter(new Date(cursor.before)),
        limit(PAGE_SIZE + 1),
      ]

  const notesRef = query(
    collection(db, `users/${userId}/notes`).withConverter(noteConverter),
    ...constraints
  )

  return collectionData(notesRef, { idField: "id" }).pipe(
    map((data) => {
      const hasNextCursor = data.length > PAGE_SIZE
      if (hasNextCursor) {
        data.pop()
      }

      return {
        data,
        nextCursor: hasNextCursor
          ? data[data.length - 1]?.createdAt?.toMillis()
          : null,
      }
    })
  )
}

export function useNotesSubscription(
  options?: Omit<
    UseInfiniteSubscriptionOptions<
      GetNoteReturn,
      Error,
      InfiniteData<GetNoteReturn, number>
    >,
    "initialPageParam" | "getNextPageParam"
  >
) {
  const { currentUser } = useAuth()
  const userId = currentUser?.uid

  if (!userId) {
    throw new Error("useNotesSubscription: currentUser is undefined")
  }

  return useInfiniteSubscription({
    subscriptionKey: ["NOTES", userId],
    subscriptionFn: ({ pageParam }) =>
      getNotes$(
        userId,
        pageParam ? { before: pageParam as number } : { after: Date.now() }
      ),
    options: {
      ...options,
      initialPageParam: 0,
      enabled: Boolean(userId),
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  })
}

export function useGetNoteById({
  noteId,
  reactQuery,
}: QueryParameter<Note> & {
  noteId: string
}) {
  const { currentUser } = useAuth()

  const noteRef = React.useMemo(
    () => doc(db, `users/${currentUser?.uid}/notes/${noteId}`),
    [currentUser?.uid, noteId]
  )

  return useQuery({
    ...reactQuery,
    queryKey: ["NOTE", noteId],
    queryFn: async () => {
      const noteDoc = await getNoteData(noteRef)

      if (noteDoc === null) {
        throw new Error("NOTE_NOT_FOUND")
      }

      return noteDoc
    },
  })
}

export function useTranscribedNote({
  note,
  reactQuery,
}: QueryParameter<string> & {
  note: Note
}) {
  const { currentUser } = useAuth()

  if (!currentUser) {
    throw new Error("useTranscribedNote: currentUser is undefined")
  }

  return useQuery({
    ...reactQuery,
    queryKey: ["NOTE", note.id, "TRANSCRIBED"],
    queryFn: async () => {
      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${note.id}`)

      try {
        return await getTranscribedNote(note, noteRef, currentUser?.uid)
      } catch (e) {
        console.warn("[useTranscribedNote]", e)
        return parseTranscript(note.transcription)
      }
    },
  })
}

export function useNoteTransforms({ noteId }: { noteId: string }) {
  const { currentUser } = useAuth()

  const transformsCollectionRef = React.useMemo(
    () =>
      collection(
        db,
        `users/${currentUser?.uid}/notes/${noteId}/transforms`
      ) as CollectionReference<Transform>,
    [currentUser?.uid, noteId]
  )

  return useSubscription<
    QuerySnapshot<Transform> | null,
    FirebaseError,
    Transform[]
  >({
    subscriptionKey: ["NOTE", "TRANSFORMS", { noteId }],
    subscriptionFn: () => fromRef<Transform>(transformsCollectionRef),
    options: {
      select: (snapshot) => {
        if (!snapshot) {
          return []
        }
        return snapshot?.docs.map((doc) => {
          const data = doc.data()
          return {
            ...data,
            id: doc.id,
          } as Transform
        })
      },
    },
  })
}

export function useNoteTransform({
  noteId,
  reactQuery,
  transformType = TransformType.Default,
}: QueryParameter<Transform> & {
  noteId: string
  transformType?: TransformType
}) {
  const { currentUser } = useAuth()

  return useQuery({
    ...reactQuery,
    queryKey: ["NOTE", "TRANSFORMS", { noteId, transformType }],
    queryFn: async () => {
      const docRef = doc(
        db,
        `users/${currentUser?.uid}/notes/${noteId}/transforms/${transformType}`
      )

      const snapshot = await getDoc(docRef)
      if (!snapshot.exists()) {
        throw new Error("TRANSFORM_NOT_FOUND")
      }

      const transform = snapshot.data() as Transform
      transform.id = snapshot.id

      return transform
    },
  })
}

// Note: useFirestoreQuery cannot use in this case due we depend on
// the clientId.
export function useSubscriptionNotesByClient({
  clientId,
  onSnapshot: _onSnapshot,
}: {
  clientId: string
  onSnapshot?: (snapshot: QuerySnapshot) => void
}) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const savedOnSnapshot = useSavedCallback(_onSnapshot)

  const notesRef = React.useMemo(
    () => collection(db, `users/${currentUser?.uid}/notes`),
    [currentUser?.uid]
  )

  const queryKey = ["NOTES", { clientId }]

  const result = useQuery<QuerySnapshot, FirestoreError, Note[]>({
    queryKey,
    gcTime: 30000,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (snapshot) =>
      snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Note[],
  })

  React.useEffect(() => {
    if (!clientId) return

    const q = query(
      notesRef,
      where("clientId", "==", clientId),
      orderBy("createdAt", "desc")
    )

    const unsubscribe = onSnapshot(q, (snapshot) => {
      queryClient.setQueryData(queryKey, snapshot)
      savedOnSnapshot?.(snapshot)
    })

    return () => unsubscribe?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, notesRef, clientId])

  return result
}

/* Mutations */
export function useUpdateNote(
  options?: MutationOptions<{
    noteId: string
    editedTranscription: string
  }>
) {
  const { currentUser } = useAuth()

  return useMutation({
    ...options,
    mutationKey: ["UPDATE_NOTE"],
    mutationFn: async ({ noteId, editedTranscription }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${noteId}`)

      await updateDoc(noteRef, {
        editedTranscription,
        status: NoteStatus.Edited,
      })
    },
  })
}

export function useUpdateNoteStatus(
  options?: MutationOptions<{
    noteId: string
    noteStatus: NoteStatus
  }>
) {
  const { currentUser } = useAuth()

  if (!currentUser) {
    throw new Error("useUpdateNoteStatus: currentUser is undefined")
  }

  return useMutation({
    ...options,
    mutationKey: ["UPDATE_NOTE_STATUS"],
    mutationFn: async ({ noteId, noteStatus }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${noteId}`)

      await updateDoc(noteRef, {
        status: noteStatus,
      })
    },
  })
}

export function useDeleteNote(
  options?: MutationOptions<{
    noteId: string
  }>
) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["DELETE_NOTE"],
    mutationFn: async ({ noteId }) => {
      const userId = currentUser!.uid
      const deleteRequest = {
        userId,
        payload: {
          kind: "note-delete",
          noteId,
        },
      }

      const batch = writeBatch(db)

      const inboxMsgRef = doc(collection(db, "tasks/messages/inbox"))
      batch.set(inboxMsgRef, deleteRequest)

      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${noteId}`)
      batch.update(noteRef, {
        deletedAt: serverTimestamp(),
        viewedWithUndo: false,
      })

      await batch.commit()
    },
    onSettled: (_, error, ..._props) => {
      options?.onSettled?.(_, error, ..._props)

      if (!error) {
        // Log the event
        console.log("deleting a note")
        void track("note_deleted")
      }
    },
  })
}

export function useUndoDeleteNote(
  options?: MutationOptions<{
    noteId: string
  }>
) {
  const { currentUser } = useAuth()

  return useMutation({
    ...options,
    mutationKey: ["UNDO_DELETE_NOTE"],
    mutationFn: async ({ noteId }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${noteId}`)

      await updateDoc(noteRef, {
        deletedAt: deleteField(),
        viewedWithUndo: deleteField(),
      })
    },
  })
}

export function useRequestImprovedTranscript(
  options?: MutationOptions<{
    note: Note
    transcription: string
  }>
) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["REQUEST_IMPROVED_TRANSCRIPT"],
    mutationFn: async ({ note, transcription }) => {
      const noteRef = doc(db, `users/${currentUser!.uid}/notes/${note.id}`)

      const improveCollectionRef = collection(db, "improvementRequests")
      const improvementRequestDoc = {
        userId: currentUser?.uid,
        createdAt: serverTimestamp(),
        noteRef: noteRef.path,
        noteId: note.id,
        language: note.language,
        storageRef: note.storageRef,
        duration: note.duration,
        transcription,
      }

      const requestRef = await addDoc(
        improveCollectionRef,
        improvementRequestDoc
      )

      // Update state of transcription to 'improving' and add the improvement request reference
      await updateDoc(noteRef, {
        status: NoteStatus.ImprovementRequested,
        improvementRequestRef: requestRef.path,
      })

      return {
        id: note.id,
        status: NoteStatus.ImprovementRequested,
        improvementRequestRef: requestRef.path,
      }
    },
    onSettled: async (_, error, ..._props) => {
      options?.onSettled?.(_, error, ..._props)

      if (!error) {
        // Update user statistics (in side effect)
        const dataRef = doc(db, `users/${currentUser?.uid}`)
        await updateDoc(dataRef, {
          "statistics.totalImprovementRequests": increment(1),
        })

        // Log the event
        console.log("requesting an improved transcript")
        void track("improved_transcript_requested")
      }
    },
  })
}

export function useUpdateNotesViewedWithUndo() {
  const mutationFn = async ({ currentUserId }) => {
    try {
      const notesRef = collection(db, `users/${currentUserId}/notes`)
      const q = query(notesRef, where("viewedWithUndo", "==", false))
      const snapshot = await getDocs(q)

      // Set all notes to viewedWithUndo
      const batch = writeBatch(db)

      snapshot.forEach((doc) => {
        const noteRef = doc.ref
        batch.update(noteRef, { viewedWithUndo: true })
      })

      await batch.commit()
    } catch (error) {
      console.error("Error updating viewedWithUndo for notes:", error)
      throw error
    }
  }

  return useMutation<void, FirebaseError, { currentUserId: string }>({
    mutationFn,
  })
}

export function useUpdateClientNote(
  options: MutationOptions<{
    noteId: string
    clientId: string
    clientName: string
  }>
) {
  const { currentUser } = useAuth()

  const mutationFn = async ({
    noteId,
    clientId,
    clientName,
  }: {
    noteId: string
    clientId: string
    clientName: string
  }) => {
    const noteRef = doc(db, `users/${currentUser?.uid}/notes/${noteId}`)

    await updateDoc(noteRef, { title: clientName, clientId })
  }

  return useMutation({
    ...options,
    mutationFn,
  })
}

export function useMarkNoteAsViewed() {
  const { currentUser } = useAuth()

  return useQueue<{ noteId: string }>({
    name: "[useMarkNoteAsViewed]",
    concurrency: 10,
    worker: async ({ noteId }) => {
      const userId = currentUser!.uid
      const noteRef = doc(db, `users/${userId}/notes/${noteId}`)

      await updateDoc(noteRef, { viewed: true })
      console.log("[useMarkNoteAsViewed] marked note as viewed", noteId)
    },
  })
}

export function useCopyNoteToClipboard({ onCopy }: { onCopy?: () => void }) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  const queue = useQueue<{ noteId: string }>({
    name: "[useCopyNoteToClipboard]",
    concurrency: 10,
    worker: async ({ noteId }) => {
      const userId = currentUser!.uid

      const noteRef = doc(db, `users/${userId}/notes/${noteId}`)
      await updateDoc(noteRef, { copied: true })

      console.log("[useCopyNoteToClipboard]", noteId)
    },
  })

  const copyFn = React.useCallback(async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported")
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch (error) {
      console.warn("Copy failed", error)
      return false
    }
  }, [])

  const copy = async ({ noteId, text }: { noteId: string; text: string }) => {
    const isCopied = await copyFn(text)

    if (isCopied) {
      onCopy?.()
    }

    void queue.push({ noteId })
    void track("text_copied_to_clipboard")
  }

  return {
    copy,
  }
}
