type SemverInfo = {
  major: number
  minor: number
  patch: number
  prerelease?: string
  build?: string
}

/**
 * Parse a Semver string.
 *
 * @param version The Semver string.
 * @returns An object with major, minor, and patch numbers, or null if invalid.
 */
function parseSemver(version: string): SemverInfo | null {
  const semverPattern = /^v?(\d+)\.(\d+)\.(\d+)(?:-([\da-z-.]+))?(?:\+([\da-z-.]+))?$/i
  const matches = semverPattern.exec(version)

  if (!matches) return null

  const [, major, minor, patch, prerelease, build] = matches
  return {
    major: parseInt(major, 10),
    minor: parseInt(minor, 10),
    patch: parseInt(patch, 10),
    prerelease: prerelease || undefined,
    build: build || undefined,
  }
}

export default parseSemver
