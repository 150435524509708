import React, { useState } from "react"
import { logEvent } from "firebase/analytics"
import { useNavigate, useParams } from "react-router-dom"
import ClientDetailsModal from "~/components/ClientDetailsModal"
import EditClientModal from "~/components/EditClientModal"
import { Button } from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { useGetNoteById, useNoteTransforms } from "~/hooks/useNotes"
import { analytics } from "~/services/firebase"
import { clientFields } from "~/utils/noteUtils"
import ClientModal from "./ClientModal"
import NoteHeader from "./NoteHeader"
import NoteTransformButtons from "./NoteTransformButtons"
import NoteTransformContent from "./NoteTransformContent"
import { TransformType } from "./types"

interface Field {
  name: string
  type: string
  value: string
}

export default function NoteTransformSelection() {
  const navigate = useNavigate()
  const { noteId } = useParams()
  const { currentUser } = useAuth()

  const [selectedButton, setSelectedButton] = useState<TransformType>(
    TransformType.Default
  )
  const [availableFields, setAvailableFields] = useState<Field[]>(clientFields)
  const [openModal, setOpenModal] = useState(false)
  const [openClientDetailsModal, setOpenClientDetailsModal] = useState(false)
  const [editClientModal, setEditClientModal] = useState(false)

  const noteInfo = useGetNoteById({ noteId: noteId! })
  const noteData = noteInfo.data ?? null

  // Redirect to notes page if note does not exist
  React.useEffect(() => {
    if (noteInfo.error) {
      navigate("/notes")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteInfo.error])

  const transformsQuery = useNoteTransforms({ noteId: noteId! })
  const transforms = transformsQuery.data ?? []

  const handleTransformClick = (transformType: TransformType) => {
    if (!currentUser?.uid || !noteId) return

    logEvent(analytics, `navigate_from_selection_to_${transformType}_preview`)

    navigate(`/notes/${noteId}/template-preview`, {
      state: { transformType },
    })
  }

  const handleKeepUntouched = () => {
    if (!currentUser?.uid || !noteId) return

    logEvent(analytics, "transform_keep_untouched")

    navigate(`/notes/${noteId}`)
  }

  return (
    <div className="bg-white h-full text-normal-black-400 flex flex-col">
      <NoteHeader
        noteData={noteData}
        setOpenClientDetailsModal={setOpenClientDetailsModal}
        setOpenModal={setOpenModal}
      />
      <div className="h-[50rem] md:h-[45rem] flex flex-col overflow-auto pb-20 md:pb-4">
        <h1 className="font-platypi text-2xl leading-normal font-medium flex justify-center py-8 mb-6">
          Select a text format
        </h1>

        <NoteTransformButtons
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
        />

        <NoteTransformContent
          selectedButton={selectedButton}
          handleTransformClick={handleTransformClick}
          transforms={transforms}
        />

        <Button
          size={"sm"}
          variant="link"
          className="py-2 px-4 -mt-2 mb-4 text-sm text-black hover:text-primary"
          onClick={handleKeepUntouched}
        >
          Leave text untouched
        </Button>
      </div>

      <ClientModal
        noteId={noteData?.id || ""}
        isOpen={openModal}
        onOpenChange={setOpenModal}
      />

      {noteData && (
        <>
          <ClientDetailsModal
            isOpen={openClientDetailsModal}
            setOpenClientDetailsModal={setOpenClientDetailsModal}
            clientId={noteData?.clientId || ""}
            editButtonHandler={() => setEditClientModal(true)}
          />

          {noteData.clientId && noteId && (
            <EditClientModal
              isOpen={editClientModal}
              setOpenModal={setEditClientModal}
              clientId={noteData.clientId}
              onSuccess={async () => {
                await noteInfo?.refetch()
              }}
              addedFields={[]}
              setAvailableFields={setAvailableFields}
              availableFields={availableFields}
              onCloseAllModals={() => {
                setOpenModal(false)
                setOpenClientDetailsModal(false)
                setEditClientModal(false)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
