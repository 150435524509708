import * as React from "react";
const SvgStarsIcon = (props) => /* @__PURE__ */ React.createElement("svg", { width: 32, height: 35, viewBox: "0 0 32 35", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M12.8671 34.7801C12.6796 34.7784 12.5176 34.6523 12.4733 34.4698L10.5876 27.0257C10.2977 25.8936 9.42304 25.0036 8.29606 24.6933L0.836689 22.6302C0.657664 22.5808 0.533203 22.4188 0.533203 22.233C0.533203 22.0471 0.657668 21.8852 0.836689 21.8374L8.27904 19.9551H8.28074C9.41628 19.667 10.3114 18.7923 10.6234 17.6619L12.6864 10.2026C12.7342 10.0235 12.8962 9.90078 13.082 9.90248C13.2678 9.90248 13.4298 10.0304 13.4724 10.2111L15.3547 17.6534C15.6429 18.7907 16.5193 19.684 17.6479 19.9961L25.1056 22.0506V22.0523C25.2846 22.1 25.409 22.262 25.409 22.4478C25.409 22.6337 25.2846 22.7957 25.1056 22.8451L17.6597 24.7257C16.5225 25.0155 15.6291 25.8902 15.3154 27.0206L13.2609 34.48C13.2131 34.6573 13.0512 34.7818 12.8671 34.7801Z", fill: "#131927", style: {
  fill: "#131927",
  fill: "color(display-p3 0.0745 0.0980 0.1529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M19.8198 12.8219C19.634 12.8202 19.472 12.694 19.4277 12.5133L18.5666 9.12717C18.4285 8.58498 18.0108 8.15702 17.4721 8.0087L14.0809 7.07438C13.9018 7.02323 13.7791 6.85955 13.7808 6.67371C13.7842 6.48787 13.9104 6.3276 14.0894 6.28156L17.4704 5.42738H17.4721C18.0143 5.28927 18.4405 4.87157 18.5888 4.33108L19.5266 0.950099C19.5743 0.771074 19.7363 0.648315 19.9204 0.648315C20.1028 0.648315 20.2648 0.771074 20.3126 0.950099L21.1684 4.33108C21.3048 4.87327 21.7225 5.30124 22.263 5.44955L25.6542 6.38387H25.6525C25.8333 6.43332 25.956 6.5987 25.9543 6.78454C25.9543 6.97039 25.8264 7.13236 25.644 7.17498L22.263 8.03087C21.7208 8.16897 21.2929 8.58668 21.1428 9.12546L20.2085 12.5167H20.2102C20.1642 12.6957 20.004 12.8202 19.8198 12.8219Z", fill: "#131927", style: {
  fill: "#131927",
  fill: "color(display-p3 0.0745 0.0980 0.1529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M27.9203 18.5606C27.7328 18.5606 27.5708 18.4328 27.5265 18.2503L27.165 16.8182V16.8199C27.0542 16.3783 26.7132 16.0305 26.275 15.9094L24.836 15.5139C24.657 15.4644 24.5325 15.3008 24.5342 15.1149C24.5359 14.9291 24.6638 14.7671 24.8445 14.7228L26.275 14.3613C26.7166 14.2488 27.0627 13.9095 27.1837 13.4713L27.5793 12.0323H27.581C27.6287 11.8532 27.7907 11.7305 27.9748 11.7322C28.1624 11.7322 28.3243 11.8601 28.3687 12.0408L28.7301 13.4713C28.8409 13.9111 29.182 14.259 29.6201 14.38L31.0592 14.7756V14.7773C31.2382 14.825 31.3626 14.9904 31.3592 15.1762C31.3592 15.3621 31.2314 15.524 31.0506 15.5684L29.6202 15.9315C29.1786 16.0424 28.8308 16.3816 28.7097 16.8216L28.3142 18.2606C28.2664 18.4379 28.1045 18.5623 27.9203 18.5606Z", fill: "#131927", style: {
  fill: "#131927",
  fill: "color(display-p3 0.0745 0.0980 0.1529)",
  fillOpacity: 1
} }));
export default SvgStarsIcon;
