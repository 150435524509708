/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import { Undo2 } from "lucide-react"
import { useForm } from "react-hook-form"
import AudioPlayer from "~/components/AudioPlayer"
import NoteActions from "~/components/NoteActions"
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
} from "~/components/ui"
import { useBeforeunload } from "~/hooks/useBeforeunload"
import {
  useTranscribedNote,
  useUndoDeleteNote,
  useUpdateNote,
} from "~/hooks/useNotes"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { parseTranscript } from "~/utils/transcriptParsers"
import { NoteStatus, type Note } from "./types"

export type NoteItemProps = {
  note: Note
  initialExpanded?: boolean
  onDeletedNote?: (noteId: string) => void
  onClientRemoved?: (noteId: string) => void
}

function parseTranscribed(note: Note) {
  switch (note.status) {
    case NoteStatus.Edited:
      return note.editedTranscription.replace(/\\n/g, "\n")
    case NoteStatus.Improved:
      return note.improvedTranscription.replace(/\\n/g, "\n")
    case NoteStatus.ImprovementRequested:
      // @note: lazy load the improved transcription later
      return ""
    case NoteStatus.Transcribed:
      return parseTranscript(note.transcription)
    case NoteStatus.Error:
      return `Error transcribing note \n\n ${note.transcription}`
    default:
      return "Not transcribed yet"
  }
}

export function NoteItem({
  note,
  onDeletedNote,
  onClientRemoved,
  initialExpanded = false,
}: NoteItemProps) {
  const queryClient = useQueryClient()

  const [isExpanded, setIsExpanded] = React.useState(initialExpanded)
  const [showRecording, setShowRecording] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)

  /* Actions */
  const updateNote = useUpdateNote({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["NOTE", note.id] })
    },
  })

  const undoDeleteNote = useUndoDeleteNote({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["NOTE", note.id],
      })
    },
  })

  const isImprovementRequested = note.status === NoteStatus.ImprovementRequested
  const transcribed = useTranscribedNote({
    note,
    reactQuery: {
      enabled: isImprovementRequested,
    },
  })

  const initialValues = React.useMemo(
    () => ({
      transcription:
        note.status === NoteStatus.ImprovementRequested
          ? (transcribed.data ?? "")
          : parseTranscribed(note),
    }),
    [note, transcribed.data]
  )

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const transcription = isImprovementRequested
    ? (transcribed.data ?? "")
    : parseTranscribed(note)

  const form = useForm({
    values: {
      transcription,
    },
  })

  const contentRef = React.useRef<HTMLDivElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  // MDXEditor is not a controlled component, so we need to set the markdown
  // by manually.
  React.useEffect(() => {
    if (transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  const isDirty = !!form.formState.dirtyFields?.transcription

  React.useEffect(() => {
    if (isDirty) {
      setIsEditing(true)
      contentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [isDirty])

  useBeforeunload(() => {
    if (isDirty) {
      return "You have changes to a note that will be lost."
    }
  })

  return (
    <div
      key={note.id}
      ref={contentRef}
      className={`notes-detail-bg mb-2 p-6 relative ${
        !isExpanded ? "max-h-30 overflow-hidden" : ""
      }`}
      role={isExpanded ? undefined : "button"}
      tabIndex={isExpanded ? 0 : undefined}
      data-expanded={isExpanded ? "true" : undefined}
      onClick={() => {
        if (!isExpanded) {
          setIsExpanded(true)
        }
      }}
    >
      {note.deletedAt ? (
        <div className="flex bg-medium-grey-350 justify-between font-archivo my-3 px-6 py-3 rounded-2xl bg-zinc-100/80 text-primary-black items-center">
          <span>Deleted</span>
          <Button
            type="button"
            variant="outline"
            className="bg-gray-100 border-0 px-5 py-3.5 rounded-lg text-base h-[52px]"
            isLoading={undoDeleteNote.isPending}
            onClick={() => undoDeleteNote.mutate({ noteId: note.id })}
          >
            <Undo2 className="size-4" />
            Undo
          </Button>
        </div>
      ) : (
        <Form
          ref={formRef}
          form={form}
          onSubmit={form.handleSubmit(async (values) => {
            await updateNote.mutateAsync({
              noteId: note.id,
              editedTranscription: `${values.transcription ?? ""}`.trim(),
            })

            form.reset()
          })}
        >
          <div
            tabIndex={0}
            role="button"
            className="w-fit mb-4"
            onClick={() => {
              if (isExpanded) {
                setIsExpanded(false)
              }
            }}
          >
            <p>
              <span className="text-primary-black font-mono tracking-wide text-lg font-bold capitalize">
                {formatTimestampAsNoteTitleDatePart(note.createdAt)}
              </span>
              <span> · </span>
              <span className="text-gray-500 font-mono tracking-wide text-lg font-medium">
                {formatTimestampAsNoteTitleTimePart(note.createdAt)}
              </span>
            </p>
          </div>

          {isExpanded && isEditing ? (
            <div className="relative h-12 -mx-6 overflow-hidden">
              <AnimatePresence>
                <motion.div
                  key="done-button"
                  initial={{
                    x: "100%",
                    opacity: 0,
                  }} // Start from right off-screen
                  animate={{ x: "0%", opacity: 1 }} // Slide in to view
                  exit={{
                    x: "100%",
                    opacity: 0,
                  }} // Slide out to right off-screen
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="absolute right-0"
                >
                  <Button
                    type="submit"
                    size="sm"
                    variant="secondary"
                    className="rounded-tl-md rounded-bl-md rounded-tr-none rounded-br-none min-w-[70px]"
                    isLoading={form.formState.isSubmitting}
                    onClick={() => {
                      formRef.current?.requestSubmit?.()
                      setIsEditing(false)
                    }}
                  >
                    Done
                  </Button>
                </motion.div>

                <motion.div
                  key="cancel-button"
                  initial={{
                    x: "0%",
                    opacity: 0,
                  }} // Start from off-screen left
                  animate={{ x: "100%", opacity: 1 }} // Slide into view
                  exit={{ x: "0%", opacity: 0 }} // Slide out to left off-screen
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="absolute"
                >
                  <Button
                    type="button"
                    size="sm"
                    variant="secondary"
                    className="rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md min-w-[70px] -mx-6"
                    onClick={() => {
                      setIsEditing(false)
                      form.reset({ ...initialValues })
                      // We need to do a more step here, reset the MDXEditor
                      editorRef.current?.setMarkdown(
                        initialValues.transcription
                      )
                    }}
                  >
                    Cancel
                  </Button>
                </motion.div>
              </AnimatePresence>
            </div>
          ) : (
            isExpanded && (
              <div className="relative h-12 -mx-6 overflow-hidden">
                <AnimatePresence>
                  <motion.div
                    key="edit-button"
                    initial={{
                      x: "100%",
                      opacity: 0,
                    }} // Start from right off-screen
                    animate={{ x: "0%", opacity: 1 }} // Slide in to view
                    exit={{
                      x: "100%",
                      opacity: 0,
                    }} // Slide out to right off-screen
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="absolute right-0"
                  >
                    <Button
                      type="button"
                      size="sm"
                      variant="secondary"
                      className="rounded-tl-md rounded-bl-md rounded-tr-none rounded-br-none min-w-[70px]"
                      onClick={() => {
                        setIsEditing(true)
                        form.setFocus("transcription")
                      }}
                    >
                      Edit
                    </Button>
                  </motion.div>
                </AnimatePresence>
              </div>
            )
          )}

          <FormField
            control={form.control}
            name="transcription"
            rules={{
              required: "Transcription is required",
            }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <RichEditor
                    {...field}
                    ref={(ref) => {
                      field.ref(ref)
                      editorRef.current = ref
                    }}
                    readOnly={isImprovementRequested && transcribed.isLoading}
                    placeholder={"Edit your note here"}
                    onRequestSave={() => {
                      if (form.formState.isDirty) {
                        formRef.current?.requestSubmit?.()
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {showRecording && (
            <AudioPlayer
              storageId={note?.storageRef as string}
              onClose={setShowRecording}
            />
          )}

          <div className="flex flex-col items-start mt-5">
            <NoteActions
              note={note}
              setShowRecording={setShowRecording}
              onDeletedNote={onDeletedNote}
              onClientRemoved={onClientRemoved}
            />
          </div>
        </Form>
      )}

      {!isExpanded ? (
        <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none" />
      ) : null}
    </div>
  )
}
