import React from "react"

type Props = {
  children: React.ReactNode
}

const BoxContainer: React.FC<Props> = ({ children }: Props): JSX.Element => {
  return (
    <main className='my-auto mx-auto py-4 pb-10 flex flex-col gap-4 max-w-full w-[400px] rounded-sm'>
      {children}
    </main>
  )
}

export default BoxContainer;
