// Constants for Recorder page Volume visualizer Canvas
export const canvasWidth = 295
export const canvasHeight = 80

// Constants for Recorder page bar and mime_type
export const BAR_WIDTH = 7
export const BAR_HEIGHT_MIN = 7
export const BAR_HEIGHT_MAX = 60
export const BAR_GAP = 5
export const UNKNOWN_MIME_TYPE: string = 'unknownMimeType'
export const UNKNOWN_MIME_TYPE_FILE_NAME: string = 'audio.unknown'

// Constants for Notes page
export const PAGE_SIZE = 100

// Constants for Notes page scrolling
export const SCROLL_UP_THRESHOLD = 60
export const SCROLL_DOWN_THRESHOLD = 60
