import { RxCheckCircled, RxCircle } from 'react-icons/rx'

type Props = {
  passwordLength: number
}

const PasswordLengthInfo: React.FC<Props> = ({ passwordLength }: Props) => (
  <p className="text-sm flex pt-1.5">
    {passwordLength >= 8 ? (
      <RxCheckCircled data-testid="checked-circle" className="w-5 h-5" />
    ) : (
      <RxCircle data-testid="circle" className="w-5 h-5" />
    )}
    <span className="ml-1">Minimum 8 characters</span>
  </p>
)

export default PasswordLengthInfo
