import * as React from "react"
import { useAnalytics } from "use-analytics"
import {
  Button,
  Credenza as Dialog,
  CredenzaBody as DialogBody,
  CredenzaContent as DialogContent,
  CredenzaFooter as DialogFooter,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
} from "~/components/ui"

type ReferModalProps = {
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

async function shareReferral() {
  try {
    await navigator.share({
      title: "Joy Notes",
      url: "https://joynotes.app?referral=12345",
    })
  } catch (err: any) {
    console.error("Share failed:", err)
  }
}

export function ReferModal({
  isOpen,
  onOpenChange,
  children,
}: React.PropsWithChildren<ReferModalProps>) {
  const { track } = useAnalytics()

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={onOpenChange}
        shouldScaleBackground={false}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>

        <DialogContent
          indicator={false}
          className="px-0 pt-0 border-0 rounded-t-[3rem] md:rounded-t-xl [&_svg]:text-white"
        >
          <div className="block md:hidden absolute inset-x-0 mx-auto mt-4 h-2 w-[100px] rounded-full bg-white/80" />

          <DialogHeader className="text-white pt-8 bg-[url(/img/Christmas-greeting-card.jpg)] bg-cover bg-center h-[220px] rounded-t-[3rem] md:rounded-t-xl overflow-hidden">
            <DialogTitle className="sr-only">Refer a friend</DialogTitle>
          </DialogHeader>

          <DialogBody className="text-center py-8">
            <div className="mb-6 space-y-2.5">
              <p>Joy Notes helpful? Share!</p>

              <p>
                <strong>5 referrals</strong> gifts a<br />
                <strong>Christmas dinner</strong> to someone in need.
              </p>

              <p>
                Plus, if a referral becomes a regular user, <br />
                you get a <strong>£25 Amazon voucher.</strong>
              </p>

              <p>Let’s make Christmas a little brighter 🎄 💫</p>
            </div>

            <Button
              size="lg"
              variant="destructive"
              className="h-[4.5rem] rounded-[1.5rem] text-lg bg-[#CC4333]"
              onClick={(e) => {
                e.preventDefault()
                shareReferral().catch(console.error)
                void track("share_referral")
              }}
            >
              Share now
            </Button>
          </DialogBody>

          <DialogFooter className="text-center sm:justify-center text-xs text-muted-foreground">
            <p>Refer by 31 Dec 2024. £25 gift card unlocked at 20 notes.</p>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
