import * as React from "react"
import { Slottable } from "@radix-ui/react-slot"
import { AnimatePresence, motion } from "framer-motion"
import { Loader2 } from "lucide-react"
import { Button } from "./button"
import { cn } from "./utils"

export const SubmitButton = React.forwardRef<
  React.ElementRef<typeof Button>,
  Omit<React.ComponentPropsWithoutRef<typeof Button>, "isLoading"> & {
    isSubmitting?: boolean
  }
>(({ isSubmitting, children, ...props }, ref) => {
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    let timeout: any = null

    if (isSubmitting) {
      // Start a 350ms timer to show the loading state only if the form is still submitting
      timeout = setTimeout(() => setIsLoading(true), 150)
    } else {
      // Reset loading state if form submission is done before 500ms
      if (timeout) clearTimeout(timeout)
      setIsLoading(false)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isSubmitting])

  return (
    <Button
      ref={ref}
      {...props}
      type="submit"
      disabled={isLoading || props.disabled}
      className={cn("relative overflow-hidden", props.className)}
    >
      <AnimatePresence mode="wait">
        {!isLoading && (
          <motion.span
            key={"idle"}
            exit={{
              opacity: 0,
              y: -15,
              transition: { duration: 0.3, type: "spring" },
            }}
          >
            <Slottable>{children}</Slottable>
          </motion.span>
        )}

        {isLoading && (
          <motion.span
            key={"loading"}
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 100, y: 0, transition: { delay: 0 } }}
            exit={{ opacity: 0, y: -15, transition: { duration: 0.3 } }}
          >
            <Loader2 className={cn("size-4 animate-spin")} />
          </motion.span>
        )}
      </AnimatePresence>
    </Button>
  )
})
SubmitButton.displayName = "SubmitButton"
