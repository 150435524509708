import React from "react"
import AccountField from "~/components/AccountField"
import EditPhoneNumberModal from "~/components/EditPhoneNumberModal"
import EditStringModal from "~/components/EditStringModal"
import { toast } from "~/components/ui"
import { useUserProfile, useUserUpdateProfile } from "~/hooks/useUserProfile"
import { getCountryNameFromCode } from "~/utils/countries"

interface PersonalDetailsCardProps {
  mfaEnabled: boolean
}

function PersonalDetailsCard({ mfaEnabled }: PersonalDetailsCardProps) {
  const [userProfile] = useUserProfile()

  const updateProfileMutation = useUserUpdateProfile({
    onSuccess: () => {
      toast.success("Profile updated successfully")
    },
  })

  const updateProfile = React.useCallback(
    async ({
      fieldName,
      fieldValue,
    }: {
      fieldName: string
      fieldValue: string
    }) => {
      switch (fieldName) {
        case "First name":
          await updateProfileMutation.mutateAsync({ firstName: fieldValue })
          break
        case "Last name":
          await updateProfileMutation.mutateAsync({ lastName: fieldValue })
          break
        case "Email":
          await updateProfileMutation.mutateAsync({ email: fieldValue })
          break
        case "Phone number":
          await updateProfileMutation.mutateAsync({ phoneNumber: fieldValue })
          break
        default:
          break
      }
    },
    [updateProfileMutation]
  )

  const countryName = React.useMemo(
    () =>
      userProfile?.regCountryCode
        ? getCountryNameFromCode(userProfile?.regCountryCode)
        : "",
    [userProfile?.regCountryCode]
  )

  return (
    <>
      <div className="bg-white shadow rounded-lg py-4 gap-y-3 gap-x-4">
        <h3 className="text-base leading-6 font-medium text-gray-900 px-4">
          Personal Info
        </h3>

        <hr className="border-t border-gray-200 mt-4" />

        <div className="p-4">
          <AccountField
            fieldDescription="First name"
            fieldValue={
              userProfile?.firstName
                ? userProfile?.firstName
                : "<Your first name>"
            }
          >
            <EditStringModal
              title={"Edit first name"}
              fieldName={"First name"}
              fieldValue={userProfile?.firstName || ""}
              handleSubmit={updateProfile}
            >
              <AccountField.EditButton />
            </EditStringModal>
          </AccountField>

          <hr className="my-2 mx-auto w-full border border-gray-200" />

          <AccountField
            fieldDescription="Last name"
            fieldValue={
              userProfile?.lastName ? userProfile?.lastName : "<Your last name>"
            }
          >
            <EditStringModal
              title={"Edit last name"}
              fieldName={"Last name"}
              fieldValue={userProfile?.lastName || ""}
              handleSubmit={updateProfile}
            >
              <AccountField.EditButton />
            </EditStringModal>
          </AccountField>

          <hr className="my-2 mx-auto w-full border border-gray-200" />

          <AccountField
            fieldDescription="Phone number"
            fieldValue={
              <span className="flex flex-row items-center gap-1.5">
                {userProfile?.phoneNumber && (
                  <AccountField.FlagIcon code={userProfile?.phoneNumber} />
                )}
                <span className="text-lg">
                  {userProfile?.phoneNumber
                    ? userProfile?.phoneNumber
                    : "<Your phone number>"}
                </span>
              </span>
            }
            buttonTooltip={
              mfaEnabled
                ? "Disable multi-factor authentication to edit your phone number"
                : ""
            }
          >
            <EditPhoneNumberModal
              title={"Edit phone number"}
              fieldName={"Phone number"}
              fieldValue={userProfile?.phoneNumber || ""}
              handleSubmit={updateProfile}
            >
              <AccountField.EditButton locked={mfaEnabled} />
            </EditPhoneNumberModal>
          </AccountField>

          <hr className="my-2 mx-auto w-full border border-gray-200" />

          <AccountField
            fieldValue={countryName}
            fieldDescription="Country of practice"
            buttonTooltip="You cannot change your country of practice"
          />
        </div>
      </div>
    </>
  )
}

export default PersonalDetailsCard
