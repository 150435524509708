import { Field } from '../pages/Notes/types'

export const formatFieldName = (name: string): string => {
  switch (name.toLowerCase()) {
    case 'email':
      return 'E-mail'
    case 'zip':
      return 'ZIP'
    default:
      return name.charAt(0).toUpperCase() + name.slice(1)
  }
}

export const removeDuplicateFields = (fields: Field[]): Field[] => {
  const uniqueFields: Field[] = []
  const fieldNames = new Set<string>()

  fields.forEach((field) => {
    if (!fieldNames.has(field.name.toLowerCase())) {
      fieldNames.add(field.name.toLowerCase())
      uniqueFields.push(field)
    }
  })

  return uniqueFields
}

export const removeDuplicateFieldsAndSort = (fields: Field[], order: string[] = []): Field[] => {
  const uniqueFields = removeDuplicateFields(fields)

  if (order.length === 0) {
    return uniqueFields
  }

  return uniqueFields.sort((a, b) => {
    const aIndex = order.indexOf(a.name.toLowerCase())
    const bIndex = order.indexOf(b.name.toLowerCase())

    if (aIndex === -1 && bIndex === -1) return 0
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1
    return aIndex - bIndex
  })
}
