import * as React from "react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Toaster, TooltipProvider } from "~/components/ui"
import { queryClient } from "./lib/react-query"

export function Providers({ children }: React.PropsWithChildren<{}>) {
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider delayDuration={100}>
        {children}
        <Toaster />
        <ReactQueryDevtools buttonPosition={"bottom-right"} />
      </TooltipProvider>
    </QueryClientProvider>
  )
}
