import React from "react"
import { CrownIcon } from "~/assets/images/CrownIcon"

const sizeToPaddingClass = {
  sm: "px-3 py-1.5",
  md: "px-4 py-2",
  lg: "px-6 py-2.5",
}

type Props = {
  readonly children: React.ReactNode
  readonly disabled?: boolean
  readonly onClick?: () => void
  readonly size?: "sm" | "md" | "lg"
  readonly className?: string
}

function UpgradeButton({
  children,
  disabled = false,
  onClick,
  size = "md",
  className,
}: Props) {
  // Determine padding based on size
  const paddingClass = sizeToPaddingClass[size]

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`inline-flex items-center justify-center font-medium text-base leading-6 ${paddingClass} rounded-lg cursor-pointer border border-transparent transition ease-in-out duration-150 bg-yellow-200 hover:bg-yellow-300 focus:ring-yellow-300 focus:outline-none focus:ring-4 disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
    >
      <span className="mr-2 flex items-center justify-center">
        <CrownIcon />
      </span>
      {children}
    </button>
  )
}

export default UpgradeButton
