import * as React from "react"
import type {
  QueryFunctionContext,
  QueryKey,
  UseQueryResult,
} from "@tanstack/react-query"
import {
  hashKey,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from "@tanstack/react-query"
import { Observable } from "rxjs"
import { cleanupSubscription } from "./storage"
import { useObservableQueryFn } from "./useObservableQueryFn"

type TPageParam = string | number

export type UseSubscriptionOptions<
  TSubscriptionFnData = unknown,
  TError = Error,
  TData = TSubscriptionFnData,
  TSubscriptionKey extends QueryKey = QueryKey,
> = Pick<
  UseQueryOptions<TSubscriptionFnData, TError, TData, TSubscriptionKey>,
  | "enabled"
  | "retry"
  | "retryDelay"
  | "refetchOnMount"
  | "placeholderData"
  | "select"
> & {
  /**
   * This function will fire any time the subscription successfully fetches
   * new data or the cache is updated via setQueryData.
   */
  onData?: (data: TData) => void
  onError?: (error: TError) => void
}

export type UseSubscriptionResult<
  TData = unknown,
  TError = unknown,
> = UseQueryResult<TData, TError>

export function useSubscription<
  TSubscriptionFnData = unknown,
  TError = Error,
  TData = TSubscriptionFnData,
  TSubscriptionKey extends QueryKey = QueryKey,
>(props: {
  subscriptionKey: TSubscriptionKey
  subscriptionFn: (
    context: QueryFunctionContext<TSubscriptionKey, TPageParam>
  ) => Observable<TSubscriptionFnData>
  options: UseSubscriptionOptions<
    TSubscriptionFnData,
    TError,
    TData,
    TSubscriptionKey
  >
}): UseSubscriptionResult<TData, TError> {
  const { subscriptionKey, subscriptionFn, options } = props
  const hashedSubscriptionKey = hashKey(subscriptionKey)

  const queryClient = useQueryClient()
  const { queryFn, clearErrors } = useObservableQueryFn(
    subscriptionFn,
    (data) => data
  )

  const queryResult = useQuery({
    ...options,
    queryKey: subscriptionKey,
    queryFn,
    retry: false,
    staleTime: Infinity,
    refetchInterval: undefined,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  React.useEffect(() => {
    if (queryResult.isSuccess) {
      options?.onData?.(queryResult.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data])

  React.useEffect(() => {
    if (queryResult.error) {
      clearErrors()
      options?.onError?.(queryResult.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.error])

  React.useEffect(() => {
    return function cleanup() {
      const activeObserversCount = queryClient
        .getQueryCache()
        .find(subscriptionKey as any)
        ?.getObserversCount()

      if (activeObserversCount === 0) {
        cleanupSubscription(queryClient, hashedSubscriptionKey)
      }
    }
    // This is safe as `hashedSubscriptionKey` is derived from `subscriptionKey`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, hashedSubscriptionKey])

  return queryResult
}
