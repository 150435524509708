import * as React from 'react'

export const CrownIcon = () => (
  <svg width="25px" height="25px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill="#f5be22"
        d="M19.979,18.966c-3.332,-1.638 -2.533,-4.276 -0.601,-6.567c1.497,-1.779 2.901,-3.615 3.671,-5.813c0.231,-0.656 0.448,-0.652 0.649,0.012c0.573,1.864 2.203,3.86 3.518,5.232c1.908,1.984 3.122,4.639 0.484,6.744c-0.932,0.742 -2.251,0.827 -1.359,2.533c0.398,0.761 1.016,1.311 1.855,1.65c3.515,1.423 7.051,1.426 10.609,0.008c0.573,-0.226 1.092,-0.558 1.557,-0.996c0.007,-0.006 0.013,-0.012 0.019,-0.019c0.248,-0.253 0.242,-0.666 -0.012,-0.915c-0.006,-0.006 -0.013,-0.012 -0.019,-0.018c-0.503,-0.457 -0.991,-0.947 -1.464,-1.468c-1.251,-1.38 -1.045,-3.336 0.439,-4.45c1.146,-0.86 2.33,-1.745 3.554,-2.654c1.109,-0.819 2.077,-1.791 3.029,-2.779c0.374,-0.385 0.499,-0.316 0.376,0.206l-4.369,18.39c-0.086,0.371 -0.423,0.636 -0.807,0.633c-10.001,-0.097 -20.157,-0.121 -30.467,-0.073c-1.871,0.011 -3.633,0.051 -5.284,0.121c-0.152,0.007 -0.288,-0.095 -0.323,-0.242l-4.13,-16.558c-0.084,-0.334 0.012,-0.397 0.286,-0.19c0.512,0.387 0.883,0.855 1.501,1.166c1.645,0.835 4.973,2.283 5.32,4.3c0.295,1.702 -0.673,2.807 -1.92,3.755c-0.17,0.129 -0.271,0.331 -0.271,0.544c0,0.16 0.056,0.314 0.158,0.437c0.269,0.32 0.616,0.572 1.041,0.758c3.792,1.651 7.719,1.611 11.782,-0.121c1.262,-0.538 1.985,-1.433 2.171,-2.687c0.024,-0.164 -0.027,-0.299 -0.154,-0.407c-0.242,-0.199 -0.521,-0.377 -0.839,-0.532Z"
      />
      <path
        fill="#f5be22"
        d="M20.044,36.763c-4.475,-0.009 -8.706,0.047 -12.694,0.165c-0.169,0.005 -0.32,-0.11 -0.359,-0.274l-1.57,-6.446c-0.004,-0.018 -0.006,-0.035 -0.006,-0.053c-0,-0.115 0.094,-0.21 0.21,-0.21c0.002,0 0.004,0 0.006,0c1.025,0.021 2.053,0.162 3.09,0.158c10.232,-0.043 20.466,-0.086 30.701,-0.129c0.616,-0.003 1.198,0.002 1.747,0.016c0.01,0 0.02,0.001 0.029,0.002c0.156,0.021 0.267,0.164 0.247,0.318c-0.001,0.01 -0.003,0.021 -0.006,0.031l-1.4,5.849c-0.095,0.401 -0.46,0.686 -0.875,0.681c-6.188,-0.059 -12.561,-0.095 -19.12,-0.108Z"
      />
      <rect fill="#f5be22" x="7.394" y="38.38" width="32.141" height="1.17" />
    </g>
  </svg>
)
