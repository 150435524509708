import { useState } from 'react'

import { Crisp } from 'crisp-sdk-web'
import { httpsCallable } from 'firebase/functions'
import { functions } from '~/services/firebase'

interface SignedEmailResponse {
  signedEmail: string
}

const useCrisp = () => {
  const [tokenId, setTokenId] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')

  // Fetch a signed email for the user
  const fetchSignedEmailForUser = async (): Promise<string> => {
    // Get function reference
    const getSignedEmailCloudFunction = httpsCallable<{}, SignedEmailResponse>(functions, 'getSignedEmailForUser')

    try {
      // Fetch the signed email
      const result = await getSignedEmailCloudFunction()
      if (!result.data?.signedEmail) {
        throw new Error('Signed email not found in the response')
      }
      return result.data.signedEmail
    } catch (error) {
      console.error('Error fetching a signed email:', error)
      return ''
    }
  }

  // Initialize Crisp
  const initCrisp = () => {
    Crisp.configure('102eba64-1572-40ce-9790-55dbb9d27764')
  }

  // Reset the session
  const resetSession = () => {
    try {
      Crisp.setTokenId('')
      Crisp.session?.reset()
    } catch (error) {
      // Ignore error that occurs when Crisp is not initialized
      if (error instanceof TypeError && error.message === 'window.$crisp.do is not a function') {
        // Do nothing
      } else {
        console.log('Error resetting Crisp session:', error)
      }
    }
  }

  // Fetch and set the signed email
  const setSignedEmail = async (email: string) => {
    if (email !== userEmail) {
      fetchSignedEmailForUser().then((signedEmail) => {
        Crisp.user.setEmail(email, signedEmail)
        setUserEmail(email)
      })
    }
  }

  // Set the token ID to merge with previous conversations
  const setUserToken = (crispUid: string) => {
    if (crispUid !== tokenId) {
      setTokenId(crispUid)
      Crisp.setTokenId(crispUid) // Merge conversations
      Crisp.session.reset() // Reset the session for merge to take effect
    }
  }

  return { initCrisp, resetSession, setSignedEmail, setUserToken }
}

export default useCrisp
