import { useEffect, useState } from 'react'

import { ApplicationVerifier, RecaptchaVerifier } from 'firebase/auth'
import { auth } from '~/services/firebase'

export function useRecaptcha(componentId: string) {
  const [recaptcha, setRecaptcha] = useState<ApplicationVerifier | null>(null)

  useEffect(() => {
    if (componentId === '') {
      return
    }
    const verifier = new RecaptchaVerifier(auth, componentId, {
      size: 'invisible',
      callback: () => {
        // This will be called after the user successfully signs in
        // with the reCAPTCHA code delivered to the user's phone.
        console.log('Recaptcha verifier resolved')
      },
    })

    setRecaptcha(verifier)

    return () => {
      try {
        verifier.clear()
      } catch {
        console.log('Could not clear reCaptchaVerifier')
      }
    }
  }, [componentId])

  return recaptcha
}
