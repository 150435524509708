import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "~/context/AuthContext"

// Checks if the user is logged in and if their email is verified.
// If the user is logged in and their email is verified, they can access
// the protected route.
// If they are not logged in or their email is not verified,
// they are redirected to the login page.
// If they are logged in but their email is not verified,
// they are redirected to the verify email page.
function PrivateRoute() {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return (
      <Navigate
        to="/login"
        replace
      />
    )
  }

  if (!currentUser.emailVerified) {
    return (
      <Navigate
        to="/verify-email"
        replace
      />
    )
  }

  return <Outlet />
}

export default PrivateRoute
