import { Button } from "~/components/ui";
import { useState, useEffect } from "react";
import { ArrowUpIcon } from "@radix-ui/react-icons";

const BackToTop = ({ scrollContainerRef }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (scrollContainerRef.current.scrollTop > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    scrollContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener("scroll", toggleVisibility);
      return () => {
        container.removeEventListener("scroll", toggleVisibility);
      };
    }
  }, [scrollContainerRef]);

  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mt-2">
      {isVisible && (
        <div className="relative">
          <Button
            onClick={scrollToTop}
            className="text-primary bg-transparent hover:bg-transparent relative group"
          >
            <ArrowUpIcon className="w-6 h-6 font-bold" />
            <span className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              Back to Top
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default BackToTop;
