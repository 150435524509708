import { useMemo, useState } from "react"
import { Outlet } from "react-router-dom"
import LogOut from "~/assets/icons/logout-icon.svg?react"
import Menu from "~/assets/icons/more-icon.svg?react"
import AlertModal from "~/components/AlertModal"
import Header from "~/components/Header"
import MainDrawer from "~/components/MainDrawer"
import TrialNoticeHeader from "~/components/TrialNoticeHeader"
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { cleanupActiveSubscriptions } from "~/hooks/firestore/storage"
import { useStore } from "~/hooks/useStore"
import { signout } from "~/lib/auth"
import { queryClient } from "~/lib/react-query"
import { LocalRecordings } from "~/utils/recordings"
import { RecordingSyncClient } from "~/utils/recordingSyncClient"
import "./main.css"
import { useLocation } from "react-router-dom"

export default function Main() {
  const [setNavIsOpened] = useStore((state) => [state.setNavIsOpened])
  const location = useLocation()

  const [showLocalRecordingsAlert, setShowLocalRecordingsAlert] =
    useState(false)

  const checkAllRecordingsSynced = async (): Promise<boolean> => {
    const local = new LocalRecordings()
    return local.isAllSynced()
  }

  const logout = async () => {
    try {
      // clear react-query cache
      cleanupActiveSubscriptions(queryClient)
      queryClient.clear()

      // this is best effort and shouldnt stop the db to be deleted
      await safeStopServiceWorker()

      // remove any local state
      await new Promise<void>((resolve, reject) => {
        const req = indexedDB.deleteDatabase("joy-recordings")

        req.onerror = (err) => reject(err)
        req.onsuccess = () => resolve()
      })
    } finally {
      // signout from firebase
      await signout()
    }
  }

  const safeStopServiceWorker = async () => {
    // Halt and then unregister the service worker
    try {
      await new RecordingSyncClient().stop()
    } catch (err: unknown) {
      console.debug("Failed to halt service worker, de-register anyway", err)
    }

    try {
      const registrations = await navigator.serviceWorker.getRegistrations()
      for (const registration of registrations) {
        await registration.unregister()
      }
    } catch (err: unknown) {
      console.debug("Failed to unregister service worker", err)
    }
  }

  const safeLogout = async () => {
    try {
      // Check that all files are synced
      const allSynced = await checkAllRecordingsSynced()

      // If not synced, show alert
      if (!allSynced) {
        setShowLocalRecordingsAlert(true)
        return
      }

      // Otherwise, logout
      await logout()
    } catch (err: unknown) {
      // If there is an error, signout anyway
      await signout()
      // Throw the error again
      throw err
    }
  }

  const confirmLogout = async () => {
    setShowLocalRecordingsAlert(false)
    await logout()
  }

  const closeAlertModal = () => {
    setShowLocalRecordingsAlert(false)
  }

  const showTopBar = useMemo(() => {
    return location.pathname === "/notes" || location.pathname === "/account"
  }, [location])

  return (
    <div className="h-full w-full bg-soft_green-500">
      <AlertModal
        open={showLocalRecordingsAlert}
        onClose={closeAlertModal}
        title="Local recordings available"
        description="You have recordings that have not yet been uploaded. They will be deleted if you logout before upload is finished. Are you sure you want to logout?"
        confirm="Confirm"
        dismiss="Cancel"
        onConfirm={confirmLogout}
      />

      <div
        className={`main-content-bg main-content-shadow flex flex-col m-auto h-full`}
      >
        <MainDrawer />

        <Header
          classes={`w-full bg-primary-cream-300 ${!showTopBar && "hidden"}`}
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => setNavIsOpened(true)}
                size="icon"
                variant="ghost"
                className="hover:bg-[#F0F4F8]"
              >
                <Menu />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Menu</TooltipContent>
          </Tooltip>

          <TrialNoticeHeader classes="bg-primary-cream-300 py-1" />

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={safeLogout}
                size="icon"
                variant="ghost"
                className="hover:bg-[#E3EFFB]"
              >
                <LogOut className="mr-1" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Logout</TooltipContent>
          </Tooltip>
        </Header>

        <div className="flex-1 overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
