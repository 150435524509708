import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const focusInput = [
  "focus:ring-2",
  "focus:ring-blue-200",
  "focus:border-blue-500",
]

export const focusRing = [
  "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
]

export const hasErrorInput = ["ring-2", "border-red-500", "ring-red-200"]
