import { useMemo } from "react"
import { DateTime } from "luxon"
import { useAuth } from "~/context/AuthContext"

function ContractsCard() {
  const { currentUser } = useAuth()

  // Get formatted account creation date
  const formattedDate = useMemo(() => {
    if (!currentUser?.metadata.creationTime) {
      return ""
    }
    return DateTime.fromRFC2822(
      currentUser.metadata.creationTime
    ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
  }, [currentUser])

  return (
    <div className="bg-white shadow rounded-lg py-4 gap-y-3 gap-x-4">
      <h3 className="text-base leading-6 font-medium text-gray-900 px-4">
        Your contracts
      </h3>

      <hr className="border-t border-gray-200 mt-4"></hr>

      <div className="p-4">
        <h4 className="text-sm font-medium mb-2">Account created</h4>
        <p className="text-sm mb-6">{formattedDate}</p>

        <h4 className="text-sm font-medium mb-2">Terms of service</h4>
        <a
          href="https://www.joy.day/terms-of-service"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.joy.day/terms-of-service
        </a>

        <h4 className="text-sm font-medium mt-6 mb-2">
          Data processing agreement
        </h4>
        <a
          href="https://www.joy.day/data-processing-agreement"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.joy.day/data-processing-agreement
        </a>
        <div className="bg-yellow-100 rounded-md mt-2 p-2">
          <p className="text-xs">2024-10-16</p>
          <p className="text-sm">
            Paddle.com added to list of Sub-Processors. It&apos;s used for
            payments and subscriptions.
          </p>
          <p className="text-sm">
            Paddle is provided with your account email address and any other
            information you provide during the checkout process.
          </p>
        </div>
        <div className="bg-yellow-100 rounded-md mt-2 p-2">
          <p className="text-xs">2024-08-21</p>
          <p className="text-sm">
            Sentry.io added to list of Sub-Processors. It&apos;s used for error
            tracking and performance monitoring in the app, enabling us to give
            you a better service.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContractsCard
