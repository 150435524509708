import * as React from "react"
import { useQueryClient } from "@tanstack/react-query"
import {
  CircleEllipsis,
  CopyIcon,
  NotebookPen,
  PlayIcon,
  Trash2Icon,
  UserXIcon,
  WandSparklesIcon,
} from "lucide-react"
import { useFormContext } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useAnalytics } from "use-analytics"
import { useRemoveClientFromNote } from "~/hooks/firestore/useClients"
import {
  useCopyNoteToClipboard,
  useDeleteNote,
  useRequestImprovedTranscript,
} from "~/hooks/useNotes"
import { NoteStatus, Note as NoteType } from "~/pages/Notes/types"
import { PROMPTS } from "~/utils/prompts"
import { TEMPLATE_START_DATE } from "../config"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  toast,
} from "./ui"
import { ConfirmationModal } from "./ui/confirmation-modal"

interface NoteActionsProps {
  note: NoteType
  setShowRecording: (show: boolean) => void
  onDeletedNote?: (nodeId: string) => void
  onClientRemoved?: (nodeId: string) => void
}

const getImprovementLabel = (status: NoteStatus): string => {
  switch (status) {
    case NoteStatus.ImprovementRequested:
      return "Improvement requested"
    case NoteStatus.Improved:
      return "Note improved"
    default:
      return "Request improvement"
  }
}

const NoteActions = ({
  note,
  setShowRecording,
  onDeletedNote,
  onClientRemoved,
}: NoteActionsProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const form = useFormContext()

  const { track } = useAnalytics()

  // Actions
  const invalidateNote = React.useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["NOTE"] })
    await queryClient.invalidateQueries({ queryKey: ["NOTES"] })
  }, [queryClient])

  const deleteNote = useDeleteNote({
    onSettled: async (_, error, variables) => {
      if (error) {
        toast.error("Error deleting note, please try again!")
      } else {
        onDeletedNote?.(variables.noteId)
        await invalidateNote()
      }
    },
  })

  const copyNoteToClipboard = useCopyNoteToClipboard({
    onCopy: () => {
      toast.success("Text copied to clipboard", { id: "copy" })
    },
  })

  const [showRemoveFromClientModal, setShowRemoveFromClientModal] =
    React.useState(false)
  const removeClientFromNote = useRemoveClientFromNote({
    onSettled: (_, e, vars) => {
      if (e) {
        toast.error(e.message)
      } else {
        toast.info("Note removed from client")
        onClientRemoved?.(vars.note.id)
      }
    },
  })

  const requestImprovedTranscript = useRequestImprovedTranscript({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Error requesting improvement, please try again!")
      } else {
        toast.success("Request sent")
        void invalidateNote()
      }
    },
  })

  const handleShowRecording = () => {
    track("play_recording")
    setShowRecording(true)
  }

  const handleSetFormat = () => {
    track("set_format")
    navigate(`/notes/${note.id}/template-selection`)
  }

  const setFormatEnabled = note.serverTimestamp.toDate() > TEMPLATE_START_DATE

  const dropdownItems = React.useMemo(
    () =>
      [
        {
          label: "Play recording",
          icon: <PlayIcon className="size-4" />,
          onClick: handleShowRecording,
          enabled: note.storageRef,
        },
        {
          label: getImprovementLabel(note.status),
          icon: <WandSparklesIcon className="size-4" />,
          onClick: () => {
            requestImprovedTranscript.mutate({
              note,
              transcription: form.getValues("transcription"),
            })
          },
          disabled:
            note.status === NoteStatus.Edited ||
            note.status === NoteStatus.Improved ||
            note.status === NoteStatus.ImprovementRequested,
        },
        {
          label: "Set Format",
          icon: <NotebookPen className="size-4" />,
          onClick: handleSetFormat,
          disabled:
            ![NoteStatus.Transcribed, NoteStatus.Edited].includes(
              note.status
            ) || !note.storageRef,
          enabled: setFormatEnabled,
        },
        {
          label: "Remove from client",
          icon: <UserXIcon className="size-4" />,
          onClick: () => setShowRemoveFromClientModal(true),
          enabled: Boolean(note.clientId),
        },
      ].filter((item) => item.enabled ?? true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note, form.getValues]
  )

  return (
    <>
      <div className="flex flex-row w-full justify-center gap-1 sm:gap-4">
        <ConfirmationModal
          isDestructive
          title="Delete note?"
          description="Are you sure you want to delete this note?"
          closeButton="Cancel"
          confirmButton="Delete"
          onConfirm={() => deleteNote.mutate({ noteId: note.id })}
        >
          <Button
            type="button"
            variant="outline"
            className="bg-light_gray border-0 rounded-lg text-base h-[52px]"
            isLoading={deleteNote.isPending}
          >
            <Trash2Icon className="size-4 text-red-500" />
            Delete
          </Button>
        </ConfirmationModal>

        <Button
          type="button"
          variant="outline"
          className="bg-light_gray border-0 rounded-lg text-base h-[52px]"
          onClick={(event) => {
            event.stopPropagation()
            void copyNoteToClipboard.copy({
              noteId: note.id,
              text: form.getValues("transcription"),
            })
          }}
        >
          <CopyIcon className="size-4" />
          <div className="block sm:hidden">Copy</div>
          <div className="hidden sm:block">Copy text</div>
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              type="button"
              variant="outline"
              className="bg-light_gray border-0 rounded-lg text-base h-[52px]"
            >
              <CircleEllipsis className="size-5 mr-1" />
              More
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            {dropdownItems.map((item, index) => (
              <DropdownMenuItem
                key={index}
                className="gap-2.5"
                disabled={item.disabled}
                onSelect={() => item?.onClick?.()}
              >
                {item.icon}
                <span>{item.label}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <ConfirmationModal
        isDestructive
        isOpen={showRemoveFromClientModal}
        onOpenChange={setShowRemoveFromClientModal}
        title="Remove from client?"
        description={PROMPTS.REMOVE_CLIENT_FROM_NOTE}
        closeButton="Cancel"
        confirmButton="Remove"
        onConfirm={() => removeClientFromNote.mutate({ note })}
      />
    </>
  )
}

export default NoteActions
